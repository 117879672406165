import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import CustomInputNumber from '../common-elements/custom-number-input';
import ReadOnlyField from '../common-elements/read-only-field';
import { useStores } from '../../../../../store';
import { Switch } from 'antd';

interface TableCellContentProps {
    col: any;
    apnName: string;
}

const TableCellContent: FC<TableCellContentProps> = observer(props => {

    const { useCaseStore } = useStores();

    const validateInputNumber = (
        value: number,
        validationField: string,
        apnName: string
    ) => {
        const maxValue = useCaseStore.valueByFieldName(validationField, apnName);
        return value <= maxValue;
    };

    const onChange = (value: any, colName, apnName) => {
        useCaseStore.changeParamValueByApn(apnName, colName, value);
    };

    const handleInputChange = (val, item, apnName) => {
        if (!val && val !== 0) return;

        if (item.validate && item.validate.max) {
            const isValid = validateInputNumber(val, item.validate.max, apnName);
            if (!isValid) {
                return;
            }
        }

        onChange(val, item.colField, apnName);

        if (item.dependedField && item.dependedField.max) {
            const biggerThanMax = useCaseStore.valueByFieldName(item.dependedField.max, apnName) > val;
            if (biggerThanMax) {
                onChange(val, item.dependedField.max, apnName);
            }
        }
    };

    const renderInputNumber = (item, apnName, value, decimal=false) => (
        <CustomInputNumber
            key={item.colField}
            item={item}
            value={value}
            onChange={(val) => handleInputChange(val, item, apnName)}
            label={item.label}
            decimal={decimal}
        />
    );

    const renderReadOnlyField = (item, value) => (
        <ReadOnlyField value={value} label={item.label} unit={item.unit} key={item.colField}/>
    );

    const renderBoolean = (item, apnName, value) => (
        <label key={item.colField} className={'toggle'}>
            <span>{item.label}</span>
            <Switch
                checked={value}
                onChange={(val) => onChange(val, item.colField, apnName)}
            />
        </label>

    );

    const renderPair = (items, apnName) => {
        return <div className={'items'}> {
            items.map((item) => {
                const apnPreferences = useCaseStore.apnPreferences.find(app => app.apnName === apnName);
                const parameterValue = apnPreferences?.apnParameters?.find(param => param.parameterName === item.colField)?.parameterValue;
                const value = parameterValue || parameterValue === 0 ? parameterValue : null;
                if (item.type === 'readonly') {
                    return renderReadOnlyField(item, value);
                } else if (item.type === 'number') {
                    return renderInputNumber(item, apnName, value);
                } else if (item.type === 'decimal') {
                    return renderInputNumber(item, apnName, value, true);
                } else if (item.type === 'boolean') {
                    return renderBoolean(item, apnName, value);
                }
                return null;
            })
        }
        </div>
    };

    const renderTableCellContent = (col, apnName) => {
        if (col.type === 'number' || col.type === 'decimal' || col.type === 'boolean') {
            const apnPreferences = useCaseStore.apnPreferences.find(app => app.apnName === apnName);
            const parameterValue = apnPreferences?.apnParameters?.find(param => param.parameterName === col.colField)?.parameterValue;
            const value = parameterValue || parameterValue === 0 ? parameterValue : null;
            if (col.type === 'boolean') {
                return renderBoolean(col, apnName, value);
            } else {
                return renderInputNumber(col, apnName, value, col.type === 'decimal');
            }
        } else if (col.type === 'apnName') {
            return (
                <div className={'apn-name'}>{apnName}</div>
            );
        }
        else if (col.type === 'pair') {
            return renderPair(col.items, apnName)
        }
    };
    
    return renderTableCellContent(props.col, props.apnName);
});

export default TableCellContent;