import React, { useState } from 'react';
import TextArea from 'antd/es/input/TextArea';
import { MAX_DEST_VALUES } from '../../../../../../utils/constants';
import isValidDomain from 'is-valid-domain';
import { parseValues } from '../../../../../../utils/parse';
import { validateIP } from '../../../../../../utils/util';

interface AddUrlFieldsProps {
    onValuesChange: (destTypeToValuesMap: Record<string, string>) => void;
    setHasErrors: (hasErrors: boolean) => void;
    isDuplicate: boolean;
    isMaxEntries: boolean;
}

export const initialDestTypeToValuesMap = {
    'IP': '',
    'URL': '',
};

export const createNewValuesFromMap = (map: Record<string, string>) => {
    return Object.entries(map).flatMap(([name, value]) => {
        const values = parseValues(value);
        return values.map((val) => ({ name, value: val }));
    });
};

export const AddUrlFields: React.FC<AddUrlFieldsProps> = ({ onValuesChange, setHasErrors, isDuplicate, isMaxEntries }) => {
    const [destTypeToValuesMap, setDestTypeToValuesMap] = useState(initialDestTypeToValuesMap);
    const [inputErrors, setInputErrors] = useState(initialDestTypeToValuesMap);

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>, destType: string) => {
        const inputValue = e.target.value;
        const newDestTypeToValuesMap =  {...destTypeToValuesMap, [destType]: inputValue};
        setDestTypeToValuesMap(newDestTypeToValuesMap);

        const values = parseValues(inputValue);

        const isInvalidValue = values.some((value) => {
            return (
                (destType === 'IP' && !validateIP(value)) ||
                (destType === 'URL' && !isValidDomain(value, { wildcard: true }))
            );
        });

        const errorMessage = isInvalidValue ? `Invalid ${destType}` : '';
        const newInputErrors = { ...inputErrors, [destType]: errorMessage };

        setInputErrors(newInputErrors);
        setHasErrors(Object.values(newInputErrors).some((error) => error !== ''));

        if (onValuesChange) {
            onValuesChange(newDestTypeToValuesMap);
        }
    };

    return (
        <>
            {Object.keys(destTypeToValuesMap).map((destType) => (
                <div className="textarea-wrapper" key={destType}>
                    <div className="select-label">{destType}</div>
                    <TextArea
                        className={`textarea-input ${destType}`}
                        size={"large"}
                        placeholder={`Add multiple ${destType}s`}
                        value={destTypeToValuesMap[destType]}
                        onChange={(e) => handleInputChange(e, destType)}
                        autoSize={{ minRows: 1, maxRows: 10 }}
                    />

                    {inputErrors[destType] && (
                        <div className="error-message">{inputErrors[destType]}</div>
                    )}
                </div>
            ))}
            {isDuplicate && (
                <div className="error-message">Duplicate value</div>
            )}
            {isMaxEntries && (
                <div className="error-message">Max entries is {MAX_DEST_VALUES}</div>
            )}
        </>
    );
};

export default AddUrlFields;
