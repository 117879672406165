import html2canvas from 'html2canvas';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { decodeAndCleanHtmlContent } from './parse';
import DOMPurify from 'dompurify';

const createPng = async (divToPrint: string) => {
    const temporaryDiv = document.createElement('div');
    temporaryDiv.style.padding = '24px';
    temporaryDiv.innerHTML = DOMPurify.sanitize(divToPrint);
    const imgElement = temporaryDiv.querySelector('img');
    if (imgElement) {
        imgElement.style.width = '100%';
    }
    document.body.appendChild(temporaryDiv);
    const canvas = await html2canvas(temporaryDiv);
    document.body.removeChild(temporaryDiv);
    const imgData = canvas.toDataURL('image/png');
    return await fetch(imgData).then(r => r.blob());
}

export const printDocument = async (divToPrint: string, fileName: string) => {
    const pngBlob = await createPng(divToPrint);
    saveAs(pngBlob, `${fileName}.png`);
}

export const printListOfDocuments = async (selectedIds: string[], divsToPrint: any, filePrefix: string = '') => {
    const zip = new JSZip();
    for (const divToPrint of divsToPrint.data) {
        const htmlContent = decodeAndCleanHtmlContent(divToPrint.body);
        const pngBlob = await createPng(htmlContent);
        zip.file(`${filePrefix} ${divToPrint.id}.png`, pngBlob);
    }
    zip.generateAsync({ type: "blob" }).then(function (content) {
        saveAs(content, `insights_${moment().format('MMDDYYYYHHmm')}.zip`);
    });
}

export const saveCsvFile = async (csvContent: string, fileName: string) => {
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    fileName = fileName.replace(/&/g, 'and');
    saveAs(blob, fileName);
}

export const createCsvFile = async (csvData: any[], fileName: string) => {
    const csvRows = [];
    const headers = Object.keys(csvData[0]);
    csvRows.push(headers.join(','));

    for (const row of csvData) {
        const values = headers.map(header => {
            const escaped = (''+row[header]).replace(/"/g, '\\"');
            return `"${escaped}"`;
        });
        csvRows.push(values.join(','));
    }

    const csv = csvRows.join('\n');
    await saveCsvFile(csv, fileName);
}
