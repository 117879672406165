import { UIBE_PREFIX } from '../utils/constants';
import axiosService from './axios.service';
import { ExceptionDeviceFileInfo } from '../dtos/exception-device-file-info.dto';

class ExceptionListService {
    getExceptionList = async (customerName: string, useCase: string): Promise<ExceptionDeviceFileInfo[]> => {
        let url = `${UIBE_PREFIX}/exception-list/exceptionListVersionHistory?customerName=${customerName}`;
        if (useCase) {
            url += `&useCase=${useCase}`;
        }
        const res = await axiosService.get(url);
        return res?.data;
    }

    getExceptionListFile = async (customerName: string, useCase: string, fileName: string): Promise<string> => {
        let url = `${UIBE_PREFIX}/exception-list/exceptionListFile?customerName=${customerName}&useCase=${useCase}`;
        if (fileName) {
            url += `&fileName=${fileName}`;
        }
        const res = await axiosService.get(url);
        return res?.data;
    }

    uploadExceptionListFile = async (customerName: string, formData: FormData) => {
        await axiosService.post(`${UIBE_PREFIX}/exception-list/uploadExceptionList?customerName=${customerName}`, formData,
            {headers: {'Content-Type': 'multipart/form-data'}});
    }
}

const exceptionListService = new ExceptionListService();
export default exceptionListService;
