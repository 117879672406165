import React from 'react';
import './with-authorization.scss';

export const WithAuthorization = (WrappedComponent) => {
    return ({ isAuthorized, ...props }) => {
        const className = 'app-permission';
        return (
            <div className={isAuthorized ? className : `${className} unauthorized`}>
                <WrappedComponent {...props} />
            </div>
        );
    };
};