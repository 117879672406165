import './button-component.scss';
import React, { FunctionComponent } from 'react';

interface ButtonComponentProps {
    text: string;
    disabled?: boolean;
    icon?: FunctionComponent;
    dataTestId?: string;
    isLoading?: boolean;
    onClick(): void;
}

const ButtonComponent = (props: ButtonComponentProps) => {
    const {text, onClick, disabled, icon: Icon, dataTestId, isLoading} = props;

    return (
        <div data-html2canvas-ignore="true" className={`button ${disabled ? 'disabled' : ''}`} onClick={onClick}
             data-testid={dataTestId}>
            {isLoading && <div className="loader"/>}
            {!isLoading && Icon && <div className={`btn-img ${disabled ? 'disabled' : ''}`}><Icon /></div>}{text}
        </div>
    );
}

export default ButtonComponent;
