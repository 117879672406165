import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useStores } from '../../store';
import { MenuOptions } from '../../../utils/enums';
import './anomalies.scss';
import { AnomalyWidgets } from './anomaly-widgets';
import { dateRangeDescription } from '../../../utils/parse';
import ButtonComponent from '../../shared/button/button-component';
import { ReactComponent as Filter } from '../../../assets/svg/filter_list_black_24dp 2.svg';
import { ReactComponent as Reset } from '../../../assets/svg/sync_32.svg';
import { AnomaliesFilter } from './anomalies-filter/anomalies-filter';
import { Sidebar } from '../../shared/sidebar/sidebar';

export const Anomalies = observer(() => {
    const {uxStore, useCaseStore, anomaliesStore} = useStores();
    const [showSidebar, setShowSidebar] = useState(false);
    const [grafanaKey, setGrafanaKey] = useState(0);

    useEffect(() => {
        uxStore.setSelectedMenuItem(MenuOptions.ANOMALIES);
    }, []);

    function onResetClick() {
        setGrafanaKey(grafanaKey + 1);
    }

    const getRegionDisplayName = (worldRegionName: string) => {
        const worldRegion = anomaliesStore.worldRegions.find(r => r.name === worldRegionName);
        return worldRegion ? worldRegion.displayName : worldRegionName;
    };

    return (
        <>
            <div className='sidebar-right'>
                <div className='widgets-header'>
                    <div className='anomaly-title'>{useCaseStore.currentUseCaseModel?.tabTitle}</div>
                    <div className='buttons-container'>
                        {anomaliesStore.filter &&
                            <div className='filter-container'>
                                {useCaseStore.currentUseCaseModel?.table && anomaliesStore.filter.apn &&
                                <div className='breadcrumb apn-name'>{anomaliesStore.filter.apn}</div>}
                                <div className='breadcrumb'>{dateRangeDescription(anomaliesStore.filter.dateRange)}</div>
                                {useCaseStore.isRoamingUseCase && anomaliesStore.filter.worldRegion && <div className='breadcrumb'>
                                    {getRegionDisplayName(anomaliesStore.filter.worldRegion)}
                                </div>}
                                <div className='filter-icon'>
                                    <ButtonComponent
                                        text='Filter'
                                        onClick={() => setShowSidebar(true)}
                                        icon={Filter}
                                    />
                                </div>
                            </div>
                        }
                        <div className='reset-and-refresh'>
                            <div onClick={onResetClick} className='reset-btn'><Reset/></div>
                        </div>
                    </div>
                </div>
                <AnomalyWidgets widgets={useCaseStore.currentUseCaseModel?.widgets}
                                savedLayoutId={useCaseStore.currentUseCaseModel?.name}
                                filter={anomaliesStore.filter} key={grafanaKey}/>
            </div>
            <Sidebar sidebarComponent={<AnomaliesFilter onClose={() => setShowSidebar(false)}
                                                        showRegionFilter={useCaseStore.isRoamingUseCase}
                                                        showApnFilter={useCaseStore.currentUseCaseModel?.table}/>}
                     isOpen={showSidebar} setIsOpen={setShowSidebar}/>
        </>
    );
});
