import React from 'react';
import { Select } from 'antd';

const NumberSelect = ({ item, value, onChange }) => {
    const options = Array.from({ length: item.max - item.min }, (_, i) => {
        const valueOption = (i + item.min).toString().padStart(2, '0');
        return { value: valueOption };
    });

    const handleChange = (selectedValues) => {
        const commaSeparatedValues = selectedValues.join(',');
        onChange(commaSeparatedValues, item.colField);
    };

    const selectedOptions = value ? value.split(',') : [];

    return (
        <div className="number-select-wrapper">
            <Select
                mode="multiple"
                placeholder="Select ..."
                onChange={handleChange}
                value={selectedOptions}
                options={options}
                style={{ minWidth: '200px' }}
            />
        </div>
    );
};

export default NumberSelect;