import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import insightsService from '../../../../services/insights.service';
import parse from 'html-react-parser';
import { ReactComponent as ArrowLeftCircle } from '../../../../assets/svg/arrow-left-circle_32.svg';
import ButtonComponent from '../../../shared/button/button-component';
import { ReactComponent as Export } from '../../../../assets/svg/export_16.svg';
import './insight-item.scss';
import { decodeAndCleanHtmlContent, formatDate } from '../../../../utils/parse';
import { printDocument } from '../../../../utils/file-saver';
import { useStores } from '../../../store';
import { InsightType, MenuOptions } from '../../../../utils/enums';

interface InsightItemProps {
    type: 'alert' | 'report';
}

export const InsightItem: React.FunctionComponent<InsightItemProps> = observer(({ type }) => {
    const [isDownloading, setIsDownloading] = useState(false);
    const [content, setContent] = useState();
    const { id } = useParams<{ id: string }>();
    const [title, setTitle] = useState('');
    const { customerStore, insightsStore } = useStores();
    const navigate = useNavigate();
    const location = useLocation();
    const pageTitle = type.charAt(0).toUpperCase() + type.slice(1);
    const parseOptions = {
        replace(domNode) {
            if (domNode.name === 'a' && domNode.attribs.href) {
                const index = domNode.attribs.href.indexOf(customerStore.selectedCustomer.name);
                if (index !== -1) {
                    const newHref = domNode.attribs.href.substring(index-1);
                    return <Link to={newHref}>{domNode.attribs.href}</Link>;
                }
            }
        },
    };

    useEffect(() => {
        if (customerStore.selectedCustomer) {
            const fetchContent = async () => {
                insightsStore.setIsLoading(true);
                const response = await insightsService.getInsightsHtmlList(type as InsightType, customerStore.selectedCustomer.name, [id]);
                if (response.data && response.data.length > 0) {
                    let cleanedContent = decodeAndCleanHtmlContent(response.data[0].body);
                    if (!cleanedContent.includes('id=main-body')) {
                        cleanedContent = addMainBodyIdToContent(cleanedContent);
                    }
                    setContent(cleanedContent);
                    setTitleByType(response);
                }
                insightsStore.setIsLoading(false);
            };
            fetchContent();
        }
    }, [type, id, customerStore.selectedCustomer]);

    function setTitleByType(response: any) {
        if (type === 'alert') {
            const dateTime = response.data[0].raiseSupressType === 'RAISE' ? response.data[0].alertRaiseDateTime : response.data[0].alertSuppressDateTime;
            setTitle(`${pageTitle} ${formatDate(dateTime)}`);
        } else {
            setTitle(`${response.data[0].subject}`);
        }
    }

    function addMainBodyIdToContent(cleanedContent: string) {
        const oldTableDef = "<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0 width=715 style='width:536.25pt'>";
        const newTableDef = "<table class=MsoNormalTable id=main-body border=0 cellspacing=0 cellpadding=0 width=715 style='width:536.25pt'>";

        let parts = cleanedContent.split(oldTableDef);

        if (parts.length > 2) {
            parts[2] = newTableDef + parts[2];
            cleanedContent = parts.join(oldTableDef);
        }
        return cleanedContent;
    }

    async function onExportClick() {
        setIsDownloading(true);
        await printDocument(content, `${pageTitle}_${id}`);
        setIsDownloading(false);
    }

    function onBackClick() {
        if (location.state === MenuOptions.DASHBOARD) {
            navigate('../dashboard');
        } else {
            navigate(`../insights/${type}s`, {state: {currentPage: location.state?.currentPage}});
        }
    }

    return (
        <div className="insight-item">
            <div className="single-item-header">
                <div className="title-container">
                    <div onClick={onBackClick} className="back-arrow" data-testid="back-arrow">
                        <ArrowLeftCircle />
                    </div>
                    <div className="title">{title}</div>
                </div>
                <ButtonComponent
                    text={isDownloading ? 'Exporting' : 'Export'}
                    onClick={() => onExportClick()}
                    icon={Export}
                    isLoading={isDownloading}
                />
            </div>
            {content && <div className="html-content" style={{background: type === 'report' ? '#FFFFFF' : '#000000'}}>
                {parse(content, parseOptions)}</div>}
        </div>
    );
});

