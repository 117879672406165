import { Link } from 'react-router-dom';
import './sub-menu.scss';

export const SubMenu = ({ options, activeSubMenu }) => {
    return (
        <div className="sub-menu">
            {options.map((value: string) => (
                <div className="sub-menu-item" key={value}>
                    <Link
                        key={value}
                        to={value.replaceAll(' ', '-').toLowerCase()}
                        className={`sub-menu-link ${activeSubMenu === value ? 'selected' : ''}`}>
                        {value}
                    </Link>
                </div>
            ))}
        </div>
    );
};