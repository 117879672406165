import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../../store';
import { Switch } from 'antd';
import { ReactComponent as DownloadIcon } from '../../../../../../assets/svg/download.svg';
import AddUrl from './add-url/add-url';
import { ReactComponent as DeleteIcon } from '../../../../../../assets/svg/delete.svg';
import CustomModal from '../../../../../shared/custom-modal/custom-modal';
import * as XLSX from '@e965/xlsx';
import { saveAs } from 'file-saver';
import { DestinationList } from './destination-list';
import TableCellContent from '../table/table-cell-content';

export const ApnItem: React.FunctionComponent<{ item: any, setActiveApn, setIsModalOpen }> = observer(({ item, setActiveApn, setIsModalOpen }) => {
    const [activeType, setActiveType] = useState<'black' | 'white'>('black');
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const { useCaseStore } = useStores();

    useEffect(() => {
        const whiteList = useCaseStore.destValueByType('white', item.apnName);
        setActiveType(whiteList?.length ? 'white' : 'black');
    }, []);

    const handleConfirmRemoveList = () => {
        useCaseStore.changeParamValueByApn(item.apnName, `${activeType}_list_destinations`, []);
        setIsConfirmModalOpen(false);
        setActiveType(activeType === 'black' ? 'white' : 'black');
    };

    const handleToggleChange = (checked) => {
        const destList = useCaseStore.destValueByType(activeType, item.apnName);
        if (destList.length) {
            setIsConfirmModalOpen(true);
        } else {
            setActiveType(checked ? 'white' : 'black');
        }
    };

    const exportToExcel = (apn, type) => {
        const destList = useCaseStore.destValueByType(activeType, item.apnName);
        const data = destList.map(({ name, value }) => [name, value]);

        const ws = XLSX.utils.aoa_to_sheet([
            ['APN', apn],
            [''],
            [type === 'white' ? 'Authorized' : 'Unauthorized'],
            ...data,
        ]);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        const wbout = XLSX.write(wb, { type: 'binary', bookType: 'xlsx' });
        const buf = new ArrayBuffer(wbout.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < wbout.length; i++) {
            view[i] = wbout.charCodeAt(i) & 0xFF;
        }
        saveAs(new Blob([buf], { type: 'application/octet-stream' }), `${apn}.xlsx`);
    };

    const deleteApn = (apnName) => {
        setActiveApn(apnName);
        setIsModalOpen(true);
    };

    return (
        <div key={`${item.apnName}-${activeType}`} className="unauthorized-item">
            <div className="fields-wrapper">
                {useCaseStore.currentUseCaseModel.table.tableColumn.map((col, colNum) => (
                    <div key={`${colNum}-item`} className={`item ${col.colField}`}>
                        <TableCellContent col={col} apnName={item.apnName} />
                    </div>
                ))}
            </div>
            <div className='unauthorized-item-table'>
                <div className='left-wrapper'>
                    <Switch
                        className={"unauthorized-switch"}
                        checkedChildren="Authorized"
                        unCheckedChildren="Unauthorized"
                        onChange={handleToggleChange}
                        checked={activeType === 'white'}
                    />
                </div>
                <div className='unauthorized-action'>
                    <div className='export-icon' onClick={() => exportToExcel(item.apnName, activeType)}>
                        <DownloadIcon/>
                    </div>
                    <AddUrl apnName={item.apnName} listType={activeType}/>
                    <div className='remove-apn' onClick={() => deleteApn(item.apnName)}>
                        <DeleteIcon/>
                    </div>
                </div>
            </div>
            <DestinationList
                apnName={item.apnName}
                type={activeType}
            />
            <CustomModal
                isOpen={isConfirmModalOpen}
                width="500px"
                title="Are you sure ?"
                onOk={handleConfirmRemoveList}
                onCancel={() => setIsConfirmModalOpen(false)}
                okText="Yes"
                cancelButtonProps={{className: 'secondary-btn'}}>
                <div className="text">
                    <div>Switching the list type will remove the current list.</div>
                    <div>Do you want to proceed?</div>
                </div>
            </CustomModal>
        </div>
    )
});
