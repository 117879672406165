import './dashboard.scss';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../store';
import React, { useEffect, useState } from 'react';
import { MenuOptions } from '../../../utils/enums';
import { AnomalyWidgets } from '../anomalies/anomaly-widgets';
import DateRangePickerComponent from '../../shared/date-range-picker/date-range-picker';
import { ReactComponent as Reset } from '../../../assets/svg/sync_32.svg';

export const Dashboard = observer(() => {
    const {uxStore, customerStore, dashboardStore, useCaseStore} = useStores();
    const [grafanaKey, setGrafanaKey] = useState(0);

    useEffect(() => {
        uxStore.setSelectedMenuItem(MenuOptions.DASHBOARD);
        if (useCaseStore.model) {
            dashboardStore.load();
        }
    }, [customerStore.selectedCustomer, useCaseStore.model]);

    const onFilterChange = (selectedOption, range) => {
        dashboardStore.setFilter({
            ...dashboardStore.filter,
            dateRange: range ?? selectedOption
        });
        dashboardStore.load();
    };

    function onResetClick() {
        setGrafanaKey(grafanaKey + 1);
        dashboardStore.load();
    }

    return (<div className='dashboard'>
        <div className='dashboard-header'>
            <div className='date-range-filter'>
                <DateRangePickerComponent defaultValue={dashboardStore.filter.dateRange}
                                          onDateRangeChange={onFilterChange}
                                          dayOffsetFilter={0}
                                          align='left'/>

            </div>
            <div onClick={onResetClick} className='reset-btn'><Reset/></div>
        </div>
        <div className='widgets-container'>
            <AnomalyWidgets widgets={useCaseStore.model?.dashboard.widgets} filter={dashboardStore.filter}
                            savedLayoutId='dashboard' key={grafanaKey}/>
        </div>
    </div>)
});
