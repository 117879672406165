import { BaseStore } from './base.store';
import { RootStore } from './index';
import { makeObservable, observable, runInAction } from 'mobx';
import exceptionListService from '../../services/exception-list.service';
import { ExceptionDeviceFileInfo } from '../../dtos/exception-device-file-info.dto';
import { message } from 'antd';

export class ExceptionListStore extends BaseStore {

    public data: ExceptionDeviceFileInfo[] = [];
    public file: string;

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this, {
            data: observable,
            file: observable,
        });
    }

    public async LoadExceptionList(customerName: string, useCase: string) {
        try {
            this.setIsLoading(true);
            const res = await exceptionListService.getExceptionList(customerName, useCase);
            runInAction(async () => {
                this.data = res;
                this.data.sort((a, b) => {
                    return new Date(b.importDate).getTime() - new Date(a.importDate).getTime();
                });
                this.setIsLoading(false);
            });
        } catch (e) {
            runInAction(() => {
                this.setIsLoading(false);
            });
        }
    }

    public async LoadExceptionListFile(customerName: string, useCase: string, fileName: string) {
        try {
            this.setIsLoading(true);
            const res = await exceptionListService.getExceptionListFile(customerName, useCase, fileName);
            runInAction(async () => {
                this.file = res;
                this.setIsLoading(false);
            });
        } catch (e) {
            runInAction(() => {
                this.file = null;
                this.setIsLoading(false);
            });
        }
    }

    public async UploadExceptionListFile(formData: FormData) {
        try {
            this.setIsLoading(true);
            const customerName = this.rootStore.customerStore.selectedCustomer.name;
            await exceptionListService.uploadExceptionListFile(customerName, formData);
            runInAction(async () => {
                this.setIsLoading(false);
                message.success('File uploaded successfully');
            });
        } catch (e) {
            runInAction(() => {
                this.setIsLoading(false);
            });
        }
    }
}
