import { BaseStore } from './base.store';
import { RootStore } from './index';
import { action, makeObservable, observable } from 'mobx';

export class ErrorModalStore extends BaseStore {

    public isOpen: boolean = false;
    public errorMessage: string = '';
    public errorStatus;

    constructor(rootStore: RootStore) {
        super(rootStore);

        makeObservable(this, {
            isOpen: observable,
            openModal: action,
            closeModal: action,
            errorMessage: observable,
            errorStatus: observable,
            setErrorStatus: action
        });
    }

    public openModal(errorMessage?: string) {
        this.errorMessage = errorMessage;
        this.isOpen = true;
    }

    public closeModal() {
        this.isOpen = false;
        this.errorStatus = null;
    }

    public setErrorStatus(errorStatus) {
        this.errorStatus = errorStatus;
    }
}
