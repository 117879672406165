import React, { useCallback, useEffect, useRef, useState } from 'react';
import 'react-day-picker/dist/style.css';
import './date-range-picker.scss';
import { DayPicker, DateRange } from 'react-day-picker';
import { ReactComponent as CalenderIcon } from '../../../assets/svg/calendar.svg';
import moment from 'moment';
import { Button } from 'antd';
import { ReactComponent as Info } from '../../../assets/svg/info.svg';
import { DATE_OPTIONS_MAP } from '../../../utils/constants';

export interface DateRangePickerProps {
    defaultValue: any;
    onDateRangeChange: (selectedOption, range) => void;
    align?: 'left' | 'right';
    isWeekOnly?: boolean;
    dayOffsetFilter?: number;
}

const DateRangePickerComponent = (props: DateRangePickerProps) => {
    const {defaultValue, onDateRangeChange, align, isWeekOnly, dayOffsetFilter} = props;
    const [selectedOption, setSelectedOption] = useState<string>();
    const [isVisible, setIsVisible] = useState(false);
    const [range, setRange] = useState<DateRange | undefined>();
    const [dateOptions, setDateOptions] = useState({});
    const [until, setUntil] = useState(new Date());

    const wrapperRef = useRef(null);

    useEffect(() => {
        if (dayOffsetFilter > 0) {
            let dateOffset = new Date();
            dateOffset.setDate(dateOffset.getDate() - dayOffsetFilter);
            setUntil(dateOffset);
        } else {
            setUntil(new Date())
        }
    }, [dayOffsetFilter]);

    useEffect(() => {
        if (typeof defaultValue === 'string') {
            setSelectedOption(defaultValue);
            setRange(undefined);
        } else {
            setRange(defaultValue);
            const newSelectedOption = getSelectionText(defaultValue);
            setSelectedOption(newSelectedOption);
        }
    }, [defaultValue]);

    const filterDateOptions = (condition) =>
        Object.fromEntries(
            Object.entries(DATE_OPTIONS_MAP)
                .filter(([key, value]) => value.dayOffsetFilter === condition)
        );

    useEffect(() => {
        if (isWeekOnly) {
            setDateOptions({'Last 7 days': {value: 7, unit: 'days'}});
        } else {
            setDateOptions(filterDateOptions(dayOffsetFilter));
        }
    }, [isWeekOnly, dayOffsetFilter]);

    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (isVisible && wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
            setIsVisible(false);
            if (dateOptions.hasOwnProperty(selectedOption)) {
                setRange(undefined);
            }
        }
    }, [isVisible]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    const handleMenuClick = (e, option) => {
        setSelectedOption(option);
        setRange(undefined);
        onDateRangeChange(option, undefined);
        setIsVisible(false);
    };

    const onApplyDateRange = () => {
        const newSelectedOption = getSelectionText(range);
        setSelectedOption(newSelectedOption);
        onDateRangeChange(newSelectedOption, range);
        setIsVisible(false);
    }

    const getSelectionText = (rng) => {
        if (rng?.from && rng?.to) {
            const from = moment(rng.from).format('MM/DD/YYYY');
            const to = moment(rng.to).format('MM/DD/YYYY');
            return `${from} - ${to}`;
        }
        return selectedOption;
    }

    const onSelectDateRange = (newRange, selectedDay) => {
        if (isWeekOnly && selectedDay) {
            newRange.from = moment(selectedDay).subtract('6', 'day').toDate();
            newRange.to = selectedDay;
        }
        setRange(newRange)
    }

    return (
        <div className={"date-range-picker-container"} ref={wrapperRef}>
            <div className="selection-date" onClick={() => setIsVisible(!isVisible)}>
                <span>{selectedOption}</span>
                <CalenderIcon/>
            </div>
            <div
                className={`date-range-picker-popup ${isVisible ? 'is-visible' : ''} ${align === 'left' ? 'left-aligned' : 'right-aligned'}`}>
                <div className="content-wrapper">
                    <div className="close-options">
                        {Object.keys(dateOptions).map((option) => (
                            <div className={`date-option ${selectedOption === option ? 'active' : ''}`}
                                 key={option}
                                 onClick={(e) => handleMenuClick(e, option)}>
                                {option}
                            </div>
                        ))}
                    </div>
                    <div className="date-range-picker">
                        <DayPicker
                            mode="range"
                            defaultMonth={until}
                            selected={range}
                            onSelect={(newRange, selectedDay) => onSelectDateRange(newRange, selectedDay)}
                            disabled={{after: until}}
                            toMonth={until}
                        />
                        <Button className="range-apply-btn secondary-btn" onClick={onApplyDateRange}
                                disabled={!range?.from || !range?.to}>Apply Date Range</Button>
                    </div>
                </div>
                <div className="info-wrapper">
                    <Info/>
                    <span>
                        {dayOffsetFilter > 0 ? <>Note: Data was last updated
                            on {moment().utc().subtract(dayOffsetFilter, 'days').endOf('day').format('MMM D, HH:mm')}.<br/></> : ''}
                        Dates will be calculated by UTC time.
                    </span>
                </div>
            </div>
        </div>);
};

export default DateRangePickerComponent;
