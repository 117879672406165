import './version-history-sidebar.scss';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../store';
import { ExceptionDeviceFileInfo } from '../../../../dtos/exception-device-file-info.dto';
import moment from 'moment';
import { saveCsvFile } from '../../../../utils/file-saver';
import { ReactComponent as DownloadIcon } from '../../../../assets/svg/blue_download_16.svg';

export interface VersionHistorySidebarProps {
    onClose: () => void;
    customerName: string;
    exceptionListFiles: ExceptionDeviceFileInfo[];
}

export const VersionHistorySidebar = observer((props: VersionHistorySidebarProps) => {
    const {exceptionListStore, useCaseStore} = useStores();

    async function onVersionClick(useCase: string, fileName: string) {
        exceptionListStore.setIsLoading(true);
        await exceptionListStore.LoadExceptionListFile(props.customerName, useCase, fileName);
        exceptionListStore.setIsLoading(false);
        if (exceptionListStore.file) {
            await saveCsvFile(exceptionListStore.file, fileName);
        }
        props.onClose();
    }

    function getUseCaseName(useCase: string) {
        return useCaseStore.useCaseDisplayValues.get(useCase).displayName || useCase;
    }

    return (<div className='version-history-container'>
        <div className='history-title'>Version History</div>
        <div className='separator'/>
        {props.exceptionListFiles.map((exception: ExceptionDeviceFileInfo) => (<div key={exception.fileName}>
                <div className='download-container link' onClick={() => onVersionClick(exception.useCase, exception.fileName)}>
                    <div className='history-subtitle link'>{`${getUseCaseName(exception.useCase)}`}</div>
                    <div><DownloadIcon/></div>
                </div>
                <div className='history-subtitle'>
                    {moment.utc(exception.importDate).format('MMMM DD, YYYY HH:mm')}</div>
                <div className='history-content'>{`${exception.firstName} ${exception.lastName}`}</div>
                <div className='separator'/>
            </div>
        ))}
    </div>);
});
