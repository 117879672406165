import { observer } from 'mobx-react-lite';
import { useStores } from '../../../store';
import React, { useEffect } from 'react';
import { SettingsSubMenu } from '../../../../utils/enums';
import './general.scss';

export const General = observer(() => {
    const {useCaseStore, uxStore, customerStore} = useStores();

    useEffect(() => {
        useCaseStore.setActiveSubMenu(SettingsSubMenu.GENERAL);
    }, []);

    return (!uxStore.showLoader && <div className='general-wrapper'>
        <div className='general-title'>Reports Mailing List</div>
        <div className='general-subtitle'>{customerStore.selectedCustomer?.reportDl.join('; ')}</div>
        <div className='general-title'>Alerts Mailing List</div>
        <div className='general-subtitle'>{customerStore.selectedCustomer?.alertsDl.join('; ')}</div>
    </div>);
});
