import { handleDateRange } from '../utils/parse';

export type DateRange = string | { from: string; to: string; };

export interface BaseFilter {
    dateRange: DateRange;
    customerName?: string;
    apns?: string;
    useCase?: string;
    notificationStatus?: string;
    count?: string;
}

export interface UIReportsFilters extends BaseFilter {
    reportFrequency?: string;
}

export interface UIAlertsFilters extends BaseFilter {
    severity?: string;
}

export class BaseRequest {
    fromDate: number;
    toDate: number;
    customerName?: string;
    apns?: string;
    useCase?: string;
    notificationStatus?: string;
    count?: string;
}

export class BEGetReportsRequest extends BaseRequest {
    reportFrequency?: string;
}

export class BEGetAlertsRequest extends BaseRequest {
    severity?: string;
}

export function convertFiltersUIToBE<T extends BaseFilter, U extends BaseRequest>(uiFilter: T, BERequest: new () => U): U {
    const { fromDate, toDate } = handleDateRange(uiFilter.dateRange);

    const beRequest = new BERequest();
    beRequest.fromDate = Number(fromDate);
    beRequest.toDate = Number(toDate);

    Object.assign(beRequest, uiFilter);
    delete beRequest['dateRange'];

    return beRequest;
}