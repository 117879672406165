import React, { useState } from 'react';
import './use-case.scss';
import { useStores } from '../../../../store';
import { observer } from 'mobx-react-lite';
import Table from './table/table';
import { UnauthorizedDestination } from './unauthorized-destination/unauthorized-destination';
import Fields from './fields/fields';
import StatusIndicator from '../../../status-indicator/status-indicator';
import Settings from './settings/settings';
import AddApn from './add-apn/add-apn';
import { Button, message } from 'antd';
import CustomModal from '../../../../shared/custom-modal/custom-modal';

const UseCase = observer(() => {
    const {useCaseStore} = useStores();
    const [errorMessage, setErrorMessage] = useState(null);

    const renderContent = () => {
        if (useCaseStore.currentUseCaseModel.name === 'unauthorized_destination') {
            return <UnauthorizedDestination />;
        }

        if (useCaseStore.currentUseCaseModel.table) {
            return <Table />;
        }

        if (useCaseStore.currentUseCaseModel.fields) {
            return <Fields />;
        }

        return <div className="no-table">Set preferences only</div>;
    };

    function isInvalidUnauthorizedDestinations() {
        const unauthorizedUseCase = useCaseStore.data?.useCasePreferences.find(useCase => useCase.name === 'unauthorized_destination');
        return unauthorizedUseCase?.preferences.apnPreferences.some(
            preferences => preferences.apnParameters.every(
                parameter => parameter.parameterValue.length === 0
            ));
    }

    const saveUseCase = () => {
        if (!!useCaseStore.inactiveUseCaseWithApn.length) {
            setErrorMessage(`Activation of ${useCaseStore.inactiveUseCaseWithApn.join(', ')} requires assignment of at least one APN.`);
        } else if (isInvalidUnauthorizedDestinations()) {
            setErrorMessage(`APN destination list under 'Unauthorized Destination' use case must have at least one entry.`);
        } else {
            useCaseStore.savePreferences().then(() =>
                message.success('Update is successful!')
            ).catch((e) => {
                console.error('Error', e);
            });
        }
    };


    return (
        <div className="use-case">
            <div className='use-case-header'>
                <div className='use-cases-sub-title'>
                    <span>{useCaseStore.currentUseCaseModel.tabTitle}</span>
                    <StatusIndicator isActive={useCaseStore.currentUseCaseValues?.isUseCaseActive} />
                </div>
                <div className='action-wrapper'>
                    <Settings />
                    <AddApn />
                    <Button disabled={!useCaseStore.isDirty} className={'save'} type="primary" onClick={saveUseCase}>Save</Button>
                </div>
            </div>
            <div className={'use-case-content'}>
                {renderContent()}
            </div>

            <CustomModal
                isOpen={errorMessage}
                width="500px"
                title="Error"
                onOk={() => setErrorMessage(null)}
                cancelButtonProps={{className: 'hide'}}
                onCancel={() => setErrorMessage(null)}
                okText="OK">
                <div className="text">
                    {errorMessage}
                </div>
            </CustomModal>

        </div>
    );
});

export default UseCase;
