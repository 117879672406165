import './sidebar.scss';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useRef } from 'react';

export interface SidebarProps {
    sidebarComponent: any;
    isOpen: boolean;
    setIsOpen: (show: boolean) => void;
    width?: string;
}

export const Sidebar = observer((props: SidebarProps) => {
    const {sidebarComponent, isOpen, setIsOpen, width} = props;
    const containerRef = useRef(null);

    const handleClickOutside = useCallback((event: MouseEvent) => {
        const {target} = event;
        let className = '';
        if (target instanceof HTMLElement) {
            className = target.className;
        }
        if (containerRef.current && !containerRef.current.contains(event.target as Node) && className !== 'ant-select-item-option-content') {
            setIsOpen(false);
        }
    }, [setIsOpen]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    return (<div className={`sidebar ${isOpen ? 'open' : ''}`}
                 style={width ? {width: width} : {}} ref={containerRef}>
        {sidebarComponent}
    </div>);
});
