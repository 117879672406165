import { observer } from 'mobx-react-lite';
import Navbar from '../use-cases-settings/use-case-management/nav-bar/nav-bar';
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { useStores } from '../../store';
import { ReactComponent as ArrowLeftCircle } from '../../../assets/svg/chevron-left.svg';
import { useParams } from 'react-router-dom';

export const AnomalyWidgetsWrapper = observer(() => {

    const { useCaseStore} = useStores();
    const [isCollapsed, setIsCollapsed] = useState(false);
    const { customerName, useCaseName } = useParams<{ customerName: string; useCaseName: string }>();
    const navigate = useNavigate();

    useEffect(() => {
        if (useCaseStore.model && useCaseStore.data) {
            if (!useCaseName) {
                if (!useCaseStore.isActiveByUseCaseName(useCaseStore.currentUseCaseModel?.name) || useCaseStore.currentUseCaseModel?.hideOnNavbar) {
                    useCaseStore.setSelectedUseCaseToFirstActive();
                }
                navigate(`${useCaseStore.currentUseCaseModel.name}`, { replace: true });
            } else {
                useCaseStore.setSelectedUseCaseByName(useCaseName);
            }
        }
    }, [useCaseName, useCaseStore.currentUseCaseModel, useCaseStore.model, customerName, useCaseStore.data]);

    return <div className='anomalies'>
            <div className={`sidebar-left ${isCollapsed ? 'collapsed' : ''}`}>
                {useCaseStore.model && <Navbar disableInactive={true} relativePath={'anomalies'} />}
                <div className="collapsed-icon" onClick={() => setIsCollapsed(!isCollapsed)}><ArrowLeftCircle/></div>
            </div>
            <Outlet />
        </div>
});