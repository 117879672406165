import React from 'react';
import './fields.scss';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../../store';
import { Tooltip } from 'antd';
import { parseContent } from '../../../../../../utils/parse';
import { ReactComponent as Info } from '../../../../../../assets/svg/info.svg';
import ReadOnlyField from '../common-elements/read-only-field';
import CustomInputNumber from '../common-elements/custom-number-input';
import NumberSelect from '../common-elements/number-select';

const Fields = observer(() => {
    const { useCaseStore } = useStores();

    const handleInputChange = (val, item) => {
        if (!val && val !== 0) return;
        onChange(val, item.colField);
    };

    const onChange = (value: any, colName) => {
        useCaseStore.changeOemParamValue(colName, value);
    };

    const renderLabelWithTooltip = (item) => (
        <div className={'label-with-tooltip'}>
            <div className={'input-label'}>{item.label}
                {item.tooltip && <Tooltip title={parseContent(item.tooltip)} placement={"top"}> <Info /> </Tooltip>}
            </div>
        </div>
    );

    const renderInputNumber = (item, value, decimal = false) => (
        <CustomInputNumber
            key={item.colField}
            item={item}
            value={value}
            onChange={(val) => handleInputChange(val, item)}
            decimal={decimal}
        />
    );

    const renderNumberSelect = (item, value) => (
        <NumberSelect
            key={`select-number-${item.colField}`}
            item={item}
            value={value}
            onChange={onChange}
        />
    );

    const renderReadOnly = (item, value) => (
       <ReadOnlyField unit={item.unit} value={value} key={item.colField} />
    );

    return (
        <div className="fields-wrapper">
            {useCaseStore.currentUseCaseModel.fields.map((field, index) => {
                const parameterValue = useCaseStore.oemParameters?.find(param => param.parameterName === field.colField)?.parameterValue;
                const value = parameterValue || parameterValue === 0 ? parameterValue : null;
                return (
                    <div key={`row-${index}`} className={'field'}>
                        <>
                            {renderLabelWithTooltip(field)}
                            {field.type === 'number' && renderInputNumber(field, value)}
                            {field.type === 'decimal' && renderInputNumber(field, value, true)}
                            {field.type === 'number-select' && renderNumberSelect(field, value)}
                            {field.type === 'readonly' && renderReadOnly(field, value)}
                        </>
                    </div>
                );
            })}
        </div>
    );
});

export default Fields;
