import { IP_REGEX } from './constants';

export const hasDuplicatesValueOnArray = (array: any[]) => {
    const values = array.map(item => item.value);
    return new Set(values).size !== values.length;
}

export const validateIP = (ipAddress) => {
    const octets = ipAddress.split('.');
    if (octets.length === 4 && octets[2] === '*' && octets[3] !== '*') {
        return false;
    } else {
        return new RegExp(IP_REGEX).test(ipAddress);
    }
}