import React, { useEffect, useMemo, useState } from 'react';
import './anomaly-widgets.scss';
import { observer } from 'mobx-react-lite';
import { GrafanaWidget } from '../grafana/grafana-widget';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import { createBaseWidgetUrl } from '../../../utils/parse';
import { useStores } from '../../store';
import { UIAnomaliesFilter } from '../../../dtos/anomalies.dto';

const ResponsiveGridLayout = WidthProvider(Responsive);

export interface AnomalyWidgetsProps {
    widgets: any[];
    savedLayoutId: string;
    filter: UIAnomaliesFilter;
}

export const AnomalyWidgets = observer((props: AnomalyWidgetsProps) => {
    const {widgets, savedLayoutId, filter} = props;
    const {customerStore, useCaseStore} = useStores();
    const [isLoading, setIsLoading] = useState(true);
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [rowsDisplay, setRowsDisplay] = useState([]);
    const [key, setKey] = useState(0);
    const [widgetSrcs, setWidgetSrcs] = useState(null);

    const baseGrafanaUrl = useMemo(() => {
        return customerStore.selectedCustomer?.isDemo
            ? useCaseStore.model?.demoBaseGrafanaUrl
            : useCaseStore.model?.baseGrafanaUrl;
    }, [customerStore.selectedCustomer, useCaseStore.model]);

    const baseWidgetUrl = useMemo(() => {
        if (baseGrafanaUrl && filter && customerStore.selectedCustomer) {
            return createBaseWidgetUrl(baseGrafanaUrl, customerStore.selectedCustomer?.name, filter.apn, filter.dateRange, filter.worldRegion);
        }
    }, [baseGrafanaUrl, customerStore.selectedCustomer, filter]);

    useEffect(() => {
        setIsLoading(true);
        setIsFirstRender(true);
        if (widgets && filter) {
            const initialSrcs = {};
            widgets.forEach(widget => {
                initialSrcs[widget.panelId] = `${baseWidgetUrl}${widget.panelId}`;
            });
            setWidgetSrcs(initialSrcs);
        }
    }, [widgets, customerStore.selectedCustomer, filter]);

    useEffect(() => {
        if (widgetSrcs) {
            const savedLayout = localStorage.getItem(`savedLayout-${savedLayoutId}`);
            const layout = savedLayout ? JSON.parse(savedLayout) : [];
            const maxRow = Math.max(...widgets.map(widget => Number(widget.row)));
            const rows = Array.from(Array(maxRow + 1).keys()).slice(1);
            setRowsDisplay(rows.flatMap((row) => getWidgetRowDisplay(row, layout, key)))
            setIsLoading(false);
        }
    }, [widgetSrcs]);

    function getWidgetRowDisplay(row, layout, idKey) {
        const widgetsInRow = widgets.filter(widget => +widget.row === row).sort((a, b) => a.col - b.col);

        return widgetsInRow.map((widget, index) => {
            const layoutItem = layout?.find(item => item.i === `widget-${row}-${index}`);
            const dataGrid = layoutItem || {
                i: `${widget.panelId}-${idKey}`,
                x: index * Math.floor(12 / widgetsInRow.length),
                y: row,
                w: Math.floor(12 / widgetsInRow.length),
                h: widget.heightScale * 2 || 2 // multiply by 2 because h can't be less than 1 and heightScale can be 0.5
            };

            return (
                <div key={`widget-${row}-${index}`} data-grid={dataGrid} className="grafana-anomaly-widget">
                    <GrafanaWidget
                        panelId={widget.panelId}
                        src={widgetSrcs[widget.panelId]}
                        topPanelSrc={widget.topPanelId ? `${baseWidgetUrl}${widget.topPanelId}` : null}
                        onLinkClick={widget.dependantPanelIds ? updateDependantPanels : null}/>
                </div>
            );
        });
    }

    const updateDependantPanels = (panelId: string, href: string) => {
        const dependantPanelIds = widgets.find(widget => widget.panelId === panelId)?.dependantPanelIds;

        if (dependantPanelIds) {
            const url = new URL(href);
            const from = url.searchParams.get('from');
            const to = url.searchParams.get('to');
            const time = url.searchParams.get('time');
            const window = url.searchParams.get('time.window');
            const oem = customerStore.selectedCustomer?.name;
            const apn = filter.apn;
            const worldRegion = filter.worldRegion;
            const dest = url.searchParams.get('dest');

            dependantPanelIds.split(',').forEach((dependantPanelId: any) => {
                const newSrc = `${baseGrafanaUrl.replaceAll('{oem}', oem).replace('{apn}', apn).replace('{world_region}', worldRegion).replace('{from}', from).replace('{to}', window ? (+time + +window) : to)}${dependantPanelId}&var-dest=${dest}`;
                setWidgetSrcs(prevSrcs => ({
                    ...prevSrcs,
                    [dependantPanelId]: newSrc
                }));
            });
        }
    }

    const handleLayoutChange = (newLayout) => {
        if (!isLoading && !isFirstRender) {
            localStorage.setItem(`savedLayout-${savedLayoutId}`, JSON.stringify(newLayout));
        } else if (isFirstRender) {
            setIsFirstRender(false);
        }
    }

    const reset = () => {
        setKey(key + 1);
        localStorage.removeItem(`savedLayout-${savedLayoutId}`);
        const maxRow = Math.max(...widgets.map(widget => Number(widget.row)));
        const rows = Array.from(Array(maxRow + 1).keys()).slice(1);
        const newRowDisplay = rows.flatMap((row) => getWidgetRowDisplay(row, [], key + 1));
        setRowsDisplay(newRowDisplay);
    };

    return (!isLoading && <div className='anomaly-widgets'>
        { rowsDisplay.length ?
            <div>
                <div className={'reset-view breadcrumb'}
                     title='Reset view to default configuration'
                     onClick={reset}>Reset View</div>
                <ResponsiveGridLayout className='layout grafana-widgets-container'
                                      rowHeight={150}
                                      cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}
                                      draggableCancel=".cancel-drag"
                                      onLayoutChange={handleLayoutChange}>
                    {rowsDisplay}
                </ResponsiveGridLayout></div>
            :
            <div className='no-data'>No data</div>}
    </div>)
});
