import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, Pagination, Select } from 'antd';
import { useStores } from '../../../store';
import { InsightType } from '../../../../utils/enums';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import './insights-table.scss';
import { observer } from 'mobx-react-lite';
import { InsightData } from '../../../../utils/model';
import { ReactComponent as ArrowDown } from '../../../../assets/svg/ArrowDropDown.svg';

interface InsightsTableProps {
    type: InsightType;
    columns: {
        key: string;
        title: string;
        dataIndex: string;
        render?: (value: any, record: InsightData, currentPage: number) => React.JSX.Element;
        widthPercentage: number;
    }[];
    data: InsightData[];
    summaryView?: boolean;
    currentPage?: number;
}

export const InsightsTable: React.FunctionComponent<InsightsTableProps> = observer((props: InsightsTableProps) => {
    const {type, columns, summaryView, data} = props;
    const [selectAll, setSelectAll] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);
    const [currentPage, setCurrentPage] = useState(props.currentPage || 1);
    const [rowsPerPage, setRowsPerPage] = useState(summaryView ? 5 : 10);
    const { insightsStore, uxStore } = useStores();

    const rowsPerPageOptions = [10, 25, 50];
    const options = rowsPerPageOptions.map(name => ({
        label: name,
        value: name,
    }));

    useEffect(() => {
        setIndeterminate(false);
        setSelectAll(false);
        setCurrentPage(props.currentPage || 1);
    }, [data]);

    const handleRowsPerPageChange = (value: number) => {
        setRowsPerPage(value);
        // Reset to the first page when changing the number of rows per page
        setCurrentPage(1);
    };

    const handleItemSelection = (checked: boolean, id: string) => {
        if (checked) {
            insightsStore.addSelectedItem(type, id);
        } else {
            insightsStore.removeSelectedItem(type, id);
        }
    }

    const onCheckboxChange = useCallback(
        (id: string, suppressId: string, e: CheckboxChangeEvent) => {
            const {checked} = e.target;
            handleItemSelection(checked, id);
            if (suppressId) {
                handleItemSelection(checked, suppressId);
            }
            const selectedItems = insightsStore.selectedItems.get(type);
            const isIndeterminate = selectedItems.length > 0 && selectedItems.length < data.length;
            setIndeterminate(isIndeterminate);
            setSelectAll(selectedItems.length === data.length);
        },
        [insightsStore, selectAll, type]
    );

    const onCheckAllChange = useCallback(
        (e: CheckboxChangeEvent) => {
            const {checked} = e.target;
            setSelectAll(checked);
            insightsStore.setSelectedItems(type, checked ? data.map((item) => item.id) : []);
            setIndeterminate(false);
        },
        [type, insightsStore, setSelectAll, data]
    );

    return <div className="insights-table">
        <div className="table-header-row">
            {!summaryView && <Checkbox
                className="checkbox"
                checked={selectAll && !indeterminate}
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
            />}
            {columns.map((column) => (
                <div key={column.key} className={`${column.key}-header`} style={{width: `${column.widthPercentage}%`}}>
                    {column.title}
                </div>
            ))}
        </div>
        <div className='table-content'>
            {data.length > 0 || uxStore.showLoader ?
                data.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
                    .map((item: any) => (
                        <div key={item.id} className="table-row">
                            {!summaryView && <Checkbox
                                className="checkbox"
                                value={item.id}
                                checked={insightsStore.selectedItems.get(type).includes(item.id)}
                                onChange={(e) => onCheckboxChange(item.id, item.suppressId, e)}
                            />}
                            {columns.map((column) => (
                                <div key={column.key} className={`${column.key}-column`}
                                     style={{width: `${column.widthPercentage}%`}}>
                                    {column.render ? column.render(item[column.dataIndex], item, currentPage) : item[column.dataIndex]}
                                </div>
                            ))}
                        </div>
                    ))
                :
                <div className="no-result">No Records Found</div>
            }
        </div>
        {!summaryView && <div className="pagination-container">
            <div className='page-size-selector'>
                <div>Rows Per Page:</div>
                <div className='page-size-dropdown'>
                    <Select
                        className='pagination-select'
                        value={rowsPerPage}
                        onChange={handleRowsPerPageChange}
                        options={options}
                        suffixIcon={<ArrowDown/>}
                    />
                </div>
            </div>
            <Pagination
                current={currentPage}
                pageSize={rowsPerPage}
                total={data.length}
                onChange={setCurrentPage}
                showTotal={(total, range) => <div>{range[0]} - {range[1]} of {total}</div>}
                showSizeChanger={false}
                hideOnSinglePage={true}
            />
        </div>}
    </div>;
});
