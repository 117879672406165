import CustomModal from '../../../../../shared/custom-modal/custom-modal';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../../store';

interface DeleteModalProps {
    isOpen: boolean;
    activeApn: string;
    setIsOpen: (isOpen: boolean) => void;
}

export const DeleteModal: React.FunctionComponent<DeleteModalProps> = observer(({ isOpen, activeApn, setIsOpen }) => {

    const { useCaseStore } = useStores();

    const handleOk = () => {
        useCaseStore.removeApn(activeApn);
        setIsOpen(false);
    };

    const handleCancel = () => {
        setIsOpen(false);
    };

    return (
        <CustomModal
            isOpen={isOpen}
            width={450}
            title="Unassign APN"
            onOk={handleOk}
            onCancel={handleCancel}
            okText="Unassign"
            cancelButtonProps={{ className: 'secondary-btn' }}
            okButtonProps={{ className: 'warning-btn' }}>
            <div>Are you sure you want to unassign this APN?</div>
        </CustomModal>
    );
});
