import React from 'react';
import {Space, Spin} from 'antd';
import './loader-component.scss';
import { ReactComponent as MTADLogo } from '../../../assets/svg/logo.svg';

const antIcon = <MTADLogo className='mtad-logo' style={{fontSize: 75}}/>;

const LoaderComponent: React.FC = () =>
    <div className="full-screen-loader" data-testid="full-screen-loader">
        <Space size="middle">
            <Spin indicator={antIcon} size='large'/>
        </Space>
    </div>;

export default LoaderComponent;
