import React from 'react';
import { InputNumber } from 'antd';
import { ALLOWED_NUMBER_INPUT_KEYS } from '../../../../../../utils/constants';

const CustomInputNumber = ({ item, value, onChange, decimal = false, label = null }) => {
    const onKeyDown = (e) => {
        if (!ALLOWED_NUMBER_INPUT_KEYS.includes(e.key) || (e.key === '.' && !decimal)) {
            e.preventDefault();
        }
    };

    const calculateInputWidth = (max) => {
        const numberOfDigits = max.toString().length;
        const baseWidth = 35;
        const widthMultiplier = 10;

        return baseWidth + numberOfDigits * widthMultiplier;
    };

    return (
        <div className={'input-wrapper'}>
            {label && <div className={'input-label'}>{label}</div>}
            <InputNumber
                className={'custom-input'}
                onChange={onChange}
                value={value}
                style={{ width: calculateInputWidth(item.max) }}
                min={item.min} max={item.max}
                onKeyDown={onKeyDown}
                precision={decimal ? 1 : 0}
                step={decimal ? 0.5 : 1}
            />
            {item.unit && <div className={'input-unit'}>{item.unit}</div>}
        </div>
    );
};

export default CustomInputNumber;
