import axiosService from './axios.service';
import {OemDetails} from '../dtos/oem-details.dto';
import {UIBE_PREFIX} from '../utils/constants';

class CustomerService {

    public getOemList = async (singleCustomerName: string): Promise<OemDetails[]> => {
        let url = `${UIBE_PREFIX}/oem?status=active`;
        if (singleCustomerName)  {
            url += `&customerName=${singleCustomerName}`;
        }
        const res = await axiosService.get(url);
        return res?.data;
    }
}

const customerService = new CustomerService();
export default customerService;
