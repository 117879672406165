import '../insights.scss';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../store';
import { useEffect } from 'react';
import { InsightsSubMenu, InsightType } from '../../../../utils/enums';
import { useLocation } from 'react-router-dom';
import { InsightsTable } from '../insights-table/insights-table';
import { formatDate } from '../../../../utils/parse';
import { useNavigate } from 'react-router';

export const Reports = observer(() => {
    const { insightsStore, customerStore, useCaseStore } = useStores();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        insightsStore.setActiveSubMenu(InsightsSubMenu.REPORTS);
        if (customerStore.selectedCustomer && useCaseStore.insightsRecordsLimit) {
            insightsStore.loadInsights();
        }
    }, [customerStore.selectedCustomer, useCaseStore.insightsRecordsLimit]);

    const columns = [
        {
            key: 'report-name-link', title: 'ID', dataIndex: 'id', widthPercentage: 25,
            render: (id, item, currentPage) => <div className='name-link' onClick={() => navigate(`../../report/${id}`, {state: {currentPage}})}>
                {id}
            </div>
        },
        {key: 'use-case', title: 'Anomaly', dataIndex: 'displayName', widthPercentage: 15},
        {
            key: 'frequency', title: 'Frequency', dataIndex: 'reportFrequency', widthPercentage: 15,
            render: (frequency) => <>{`${frequency[0]}${frequency.substring(1).toLowerCase()}`}</>
        },
        {
            key: 'date', title: 'Report Date', dataIndex: 'notificationDateTime', widthPercentage: 20,
            render: (dateTime) => <>{formatDate(dateTime)}</>
        },
    ];

    return (
        <div className='insights-container'>
            <div className='title'>Reports<span className='title-summary'>{` ${insightsStore.sizeSummary}`}</span></div>
            <InsightsTable type={InsightType.REPORT} columns={columns} data={insightsStore.data.get(InsightType.REPORT)}
                           currentPage={location.state?.currentPage}/>
        </div>
    );
});
