import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../../store';
import { Button, Checkbox, Radio, Select } from 'antd';
import './add-apn.scss';
import CustomModal from '../../../../../shared/custom-modal/custom-modal';
import AddUrlFields, {
    createNewValuesFromMap,
    initialDestTypeToValuesMap
} from '../unauthorized-destination/add-url-fields';
import { MAX_DEST_VALUES } from '../../../../../../utils/constants';
import { hasDuplicatesValueOnArray } from '../../../../../../utils/util';

const AddApn = observer(() => {

    const { useCaseStore } = useStores();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedValues, setSelectedValues] = useState<string[]>([]);
    const [options, setOptions ] = useState([]);
    const [filtersOptions, setFiltersOptions ] = useState([]);

    const [destinationType, setDestinationType] = useState<'white' | 'black'>('white');
    const [destTypeToValuesMap, setDestTypeToValuesMap] = useState(initialDestTypeToValuesMap);
    const [hasErrors, setHasErrors] = useState(false);

    useEffect(() => {
        if (isModalOpen) {
            const copy = [...useCaseStore.unSelectedApn];
            const copyList = copy.map(name => ({
                label: name,
                value: name,
            }));
            setOptions(copyList);
            setFiltersOptions(copyList);
        }
    }, [isModalOpen, useCaseStore.apnPreferences]);

    useEffect(() => {
        setSelectedValues([]);
        setDestinationType('white');
        setDestTypeToValuesMap(initialDestTypeToValuesMap);
    }, [isModalOpen]);

    const totalItemsInDestTypeToValues = useMemo(() => {
        return createNewValuesFromMap(destTypeToValuesMap)
    }, [destTypeToValuesMap]);

    const hasDuplicates = useMemo(() => {
        return hasDuplicatesValueOnArray(totalItemsInDestTypeToValues);
    }, [totalItemsInDestTypeToValues]);

    const handleOk = () => {
        useCaseStore.addApnList(selectedValues);
        if (useCaseStore.isAuthorizedUseCase) {
            selectedValues.forEach(apnName =>
                useCaseStore.initDestValues(apnName, [{ listType: destinationType, parameterValues: totalItemsInDestTypeToValues }]));
        }
        handleCancel();
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleChange = (values: string[]) => {
        setSelectedValues(values);
    };

    const dropdownRender = (menu: React.ReactNode) => {
        return (
            <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                {filtersOptions.map((option: any, index: number) => (
                    <div key={index} className="checkbox-option">
                        <Checkbox
                            checked={selectedValues.includes(option.value)}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    handleChange([...selectedValues, option.value]);
                                } else {
                                    handleChange(selectedValues.filter((value) => value !== option.value));
                                }
                            }}>
                            {option.label}
                        </Checkbox>
                    </div>
                ))}
            </div>
        );
    };

    const onSearch = (searchText) => {
        setFiltersOptions(options.filter((option: any) =>
            option.label.toLowerCase().includes(searchText.toLowerCase())
        ));
    }

    const okButtonProps = {
        disabled: !selectedValues.length || hasErrors || totalItemsInDestTypeToValues.length > MAX_DEST_VALUES
            || hasDuplicates || (useCaseStore.isAuthorizedUseCase && !totalItemsInDestTypeToValues.length)};

    const addApnModal = () => {
        return (
            <CustomModal
                isOpen={isModalOpen}
                width={480}
                title="Assign APN"
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Assign"
                okButtonProps={okButtonProps}
                cancelButtonProps={{ className: 'secondary-btn' }}>
                <>
                    <div className="select-label">APN</div>
                    <Select mode="multiple"
                            showSearch={true}
                            onSearch={onSearch}
                            size={"large"}
                            popupClassName={'dropdown-custom'}
                            style={{width: '410px'}}
                            dropdownRender={dropdownRender}
                            dropdownMatchSelectWidth={410}
                            options={options}
                            value={selectedValues}
                            onChange={handleChange}
                            placeholder="Search or select"
                            className="checkbox-select" />
                </>
                {useCaseStore.isAuthorizedUseCase && <div className="add-url-wrapper">
                    <AddUrlFields
                        isDuplicate={hasDuplicates}
                        isMaxEntries={totalItemsInDestTypeToValues.length > MAX_DEST_VALUES}
                        onValuesChange={(newDestTypeToValuesMap) => {
                            setDestTypeToValuesMap((prevState) => ({ ...prevState, ...newDestTypeToValuesMap }));
                        }}
                        setHasErrors={setHasErrors}
                    />
                    <Radio.Group
                        onChange={(e) => setDestinationType(e.target.value)}
                        value={destinationType}>
                        <Radio value={'white'}>Authorized</Radio>
                        <Radio value={'black'}>Unauthorized</Radio>
                    </Radio.Group>
                </div>}
            </CustomModal>
        );
    };

    return (
       <>
           <Button className={'add-apn-btn secondary-btn'}
               type="primary"
               onClick={() => setIsModalOpen(true)}
               disabled={!useCaseStore.currentUseCaseModel.table && !useCaseStore.isAuthorizedUseCase}>
           Assign APN</Button>
           {isModalOpen && addApnModal()}
       </>
    );
});

export default AddApn;
