import axiosService from './axios.service';
import { UIBE_PREFIX } from '../utils/constants';
import { InsightType } from '../utils/enums';
import { BEGetAlertsRequest, BEGetReportsRequest } from '../dtos/insights-filter-conversion';

class InsightsService {

    getInsightsList = async (request: BEGetReportsRequest | BEGetAlertsRequest, type: InsightType, count: number) => {
        const params = this.getURLSearchParamsFromRequest(request);
        let url = `${UIBE_PREFIX}/notification/${type.toLowerCase()}s?${params.toString()}&count=${count}`;
        const res = await axiosService.get(url);
        return res?.data;
    }

    public async getInsightsHtmlList(type: InsightType, customerName: string, alertIds: string[]) {
        return axiosService.post(`${UIBE_PREFIX}/notification/${type}Files?customerName=${customerName}`, alertIds);
    }

    getURLSearchParamsFromRequest(request: BEGetReportsRequest | BEGetAlertsRequest): URLSearchParams {
        const params = new URLSearchParams();
        Object.entries(request).forEach(([key, value]) => {
            if (value) {
                params.append(key, value);
            }
        });
        return params;
    }
}

const insightsService = new InsightsService();
export default insightsService;
