import React, { useState } from 'react';
import './table.scss';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../../store';
import { Tooltip } from 'antd';
import { ReactComponent as Info } from '../../../../../../assets/svg/info.svg';
import { ReactComponent as Delete } from '../../../../../../assets/svg/delete.svg';
import { parseContent } from '../../../../../../utils/parse';
import { DeleteModal } from '../delete-modal/delete-modal';
import TableCellContent from './table-cell-content';

const Table = observer(() => {
    const { useCaseStore } = useStores();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeApn, setActiveApn] = useState<null | string>(null);

    const deleteApn = (apnName) => {
        setActiveApn(apnName);
        setIsModalOpen(true);
    };

    return (
        <div className="table-wrapper">
            <div className="table-header">
                {useCaseStore.currentUseCaseModel.table.tableColumn.map((col, index) =>
                    <div key={`header-${index}`} className={'cell-header'} style={{width: `${100 / useCaseStore.currentUseCaseModel.table.tableColumn.length}%`}}>
                        <span>{col.colName}</span>
                        {col.tooltip && <Tooltip title={parseContent(col.tooltip)} placement={"right"}> <Info /> </Tooltip>}
                    </div>
                )}
            </div>
            <div className="table-content">
                {useCaseStore.apnPreferences.length > 0 ? useCaseStore.apnPreferences.map((apn, index) => {
                    return (
                        <div className="row" key={`row-${index}-${apn}`}>
                            {useCaseStore.currentUseCaseModel.table.tableColumn.map((col, colNum) => (
                                <div key={`${colNum}-row-${index}`} className={'cell'} style={{width: `${100 / useCaseStore.currentUseCaseModel.table.tableColumn.length}%`,}}>
                                    <TableCellContent col={col} apnName={apn.apnName} />
                                </div>
                            ))}
                            <div className={'delete'} onClick={() => deleteApn(apn.apnName)}><Delete/></div>
                        </div>
                    );
                }) : <div className="no-data">No Data</div> }
            </div>
            {isModalOpen && <DeleteModal  activeApn={activeApn} isOpen={isModalOpen} setIsOpen={setIsModalOpen}/>}
        </div>
    );
});

export default Table;
