import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../../store';
import React, { useState } from 'react';
import './unauthorized-destination.scss';
import { DeleteModal } from '../delete-modal/delete-modal';
import { ApnItem } from './apn-item';

export const UnauthorizedDestination = observer(() => {
    const { useCaseStore } = useStores();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeApn, setActiveApn] = useState<null | string>(null);

    return (
        <div className="unauthorized-destination">
            {useCaseStore.apnPreferences.map((apnPref) => {
                return <ApnItem item={apnPref} key={apnPref.apnName} setActiveApn={setActiveApn} setIsModalOpen={setIsModalOpen} />;
            })}
            {isModalOpen && <DeleteModal activeApn={activeApn} isOpen={isModalOpen} setIsOpen={setIsModalOpen}/>}
        </div>
    );
});