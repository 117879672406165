import '../insights.scss';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../store';
import React, { useEffect, useMemo } from 'react';
import { InsightsSubMenu } from '../../../../utils/enums';
import { createBaseWidgetUrl } from '../../../../utils/parse';
import { GrafanaWidget } from '../../grafana/grafana-widget';

export const Activity = observer(() => {
    const { insightsStore, customerStore, useCaseStore, userStore } = useStores();

    const baseWidgetUrl = useMemo(() => {
        const baseGrafanaUrl = customerStore.selectedCustomer?.isDemo ? useCaseStore.model?.demoBaseGrafanaUrl : useCaseStore.model?.baseGrafanaUrl;
        if (customerStore.selectedCustomer?.name && baseGrafanaUrl) {
            return createBaseWidgetUrl(baseGrafanaUrl, customerStore.selectedCustomer?.name, null, insightsStore.activeFilter.dateRange, null);
        }
    }, [customerStore.selectedCustomer, insightsStore.activeFilter.dateRange, useCaseStore.model]);


    useEffect(() => {
        insightsStore.setActiveSubMenu(InsightsSubMenu.ACTIVITY);
    }, [customerStore.selectedCustomer]);

    const excludeFlow = useMemo(() =>
        userStore.isEditorRole ? '' : 'Settings'
    , [userStore.isEditorRole]);

    const hasMultiCustomerRole = useMemo(() =>
        userStore.isMultiCustomerRole
    , [userStore.isMultiCustomerRole]);

    return (
        <div className='insights-container'>
            <div className='title'>Activity</div>
            <div className='activity-widget'>
                { baseWidgetUrl &&
                    <GrafanaWidget panelId={insightsStore.activityPanelId}
                               src={`${baseWidgetUrl}${insightsStore.activityPanelId}&var-excludeFlow=${excludeFlow}&var-viewAllCustomers=${hasMultiCustomerRole}`} />
                }
            </div>
        </div>
    )
});
