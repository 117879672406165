import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import rootStoreInstance from '../components/store';
import { ErrorCode } from '../utils/enums';
import { ERROR_MSG } from '../utils/constants';

class AxiosService {

    constructor() {
        axios.interceptors.request.use(this.addCustomHeader);
        axios.interceptors.response.use(
            this.handleSuccess,
            this.handleError
        );
    }

    private addCustomHeader = (config: AxiosRequestConfig): any => {
        config.headers['X-Redirect-Url'] = window.location.href;
        return config;
    };

    private handleSuccess = (response: AxiosResponse): AxiosResponse => {
        return response;
    };

    private readonly handleError = (error: AxiosError) => {
        const { status } = error.response || {};
        const { data } = error.response?.data as any || {};
        let errorMessage = ERROR_MSG;

        rootStoreInstance.errorModalStore.errorStatus = status;

        if (status === ErrorCode.UNAUTHENTICATED_401) {
            window.location.href = error.response.headers['x-redirect-url'];

        } else if (status !== ErrorCode.UNAUTHORIZED_403 && status >= 400 && status <= 500) {
            if (data?.text) {
                errorMessage = data.text;
            }
            rootStoreInstance.errorModalStore.openModal(errorMessage);
        }

        return Promise.reject(error);
    };

    private send = async (innerMethod: (...args: any[]) => Promise<any>, path: string, payload?: any, config?: any) => {
        return innerMethod(path, payload, config);
    };

    private innerGet = async (path: string) => {
        return axios.get(path);
    };

    private innerPost = async (path: string, payload?: any, config?: any) => {
        return axios.post(path, payload, config);
    };

    private innerPut = async (path: string, payload?: any) => {
        return axios.put(path, payload);
    };

    public get(path: string) {
        return this.send(this.innerGet, path);
    }

    public post = async (path: string, payload?: any, config?: any) => {
        return this.send(this.innerPost, path, payload, config);
    };

    public put = async (path: string, payload?: any) => {
        return this.send(this.innerPut, path, payload);
    };
}

const axiosService = new AxiosService();
export default axiosService;

