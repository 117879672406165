import {RootStore} from './index';
import { action, makeObservable, observable } from 'mobx';

export class BaseStore{
    protected rootStore: RootStore;
    public isLoading: boolean;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeObservable(this, {
            isLoading: observable,
            setIsLoading: action,
        });
    }

    public setIsLoading(isLoading: boolean) {
        this.isLoading = isLoading;
    }
}
