import './error-component.scss';
import React, { useEffect, useState } from 'react';
import { ReactComponent as MTADLogo } from '../../assets/svg/logo.svg';
import { ReactComponent as Padlock } from '../../assets/svg/padlock_96.svg';
import { Button } from 'antd';
import { useNavigate } from 'react-router';
import { useStores } from '../store';
import { ErrorCode } from '../../utils/enums';

export const ErrorComponent = () => {
    const [errorMsg, setErrorMsg] = useState('');
    const [errorSubMsg, setErrorSubMsg] = useState('');
    const { errorModalStore } = useStores();
    const navigate = useNavigate();

    useEffect(() => {
        if ([ErrorCode.UNAUTHORIZED_403, ErrorCode.UNAUTHENTICATED_401].includes(errorModalStore.errorStatus)) {
            setErrorMsg('You are unauthorized to access this service');
            setErrorSubMsg('Please contact your company administrator to request access.');
        } else {
            setErrorMsg('The service is temporarily unavailable');
            setErrorSubMsg('Please contact your system administrator.');
        }
    }, [errorModalStore.errorStatus]);

    return (
        <div className="error-container">
            <div className="header">
                <div className="logo">
                    <MTADLogo/>
                    <div className="title">AT&T MTAD</div>
                </div>
            </div>
            <div className="error-content">
                <div className='padlock-frame'>
                    <div className='inner-frame'>
                        <Padlock/>
                    </div>
                </div>
                <div className='message message-title'>{errorMsg}</div>
                <div className='message message-subtitle'>{errorSubMsg}</div>
                <Button className={'secondary-btn'}
                        type="primary"
                        onClick={() => {
                            errorModalStore.setErrorStatus(null);
                            navigate('/',{ replace: true, state: { reload: true } })}
                        }>
                    Try Again</Button>
            </div>
        </div>
    );
};
