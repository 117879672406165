import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useStores } from '../../store';
import { MenuOptions, SettingsSubMenu } from '../../../utils/enums';
import { Outlet } from 'react-router';
import { SubMenu } from '../../shared/sub-menu/sub-menu';

export const UseCaseSettings = observer(() => {

    const { uxStore, useCaseStore } = useStores();

    useEffect(() => {
        uxStore.setSelectedMenuItem(MenuOptions.SETTINGS);
    }, []);

    return (<div className='use-case-setting'>
        <div className='title-container'>
            <div className='title'>Settings</div>
        </div>
        <SubMenu
            options={Object.values(SettingsSubMenu) as string[]}
            activeSubMenu={useCaseStore.activeSubMenu}
        />
        <div className='component-container'>
             <Outlet/>
        </div>
    </div>)

});
