import { observer } from 'mobx-react-lite';
import { ReactComponent as DashboardIcon } from '../../assets/svg/grid-01.svg';
import { ReactComponent as ESimIcon } from '../../assets/svg/e-sim_24.svg';
import { ReactComponent as GearIcon } from '../../assets/svg/gear.svg';
import { ReactComponent as TrendUpIcon } from '../../assets/svg/trending-up.svg';
import './side-menu.scss';
import { Link } from 'react-router-dom';
import { useStores } from '../store';
import { MenuOptions } from '../../utils/enums';

export const SideMenu = observer(() => {

    const {customerStore, uxStore} = useStores();

    const menuItems = [{
        key: MenuOptions.DASHBOARD,
        label: 'Dashboard',
        link: '/dashboard',
        icon: <DashboardIcon/>,
    },
        {
            key: MenuOptions.ANOMALIES,
            label: 'Anomalies',
            link: '/anomalies',
            icon: <ESimIcon/>,
        },
        {
            key: MenuOptions.INSIGHTS,
            label: 'Insights',
            link: '/insights',
            icon: <TrendUpIcon/>
        },
        {
            key: MenuOptions.SETTINGS,
            label: 'Settings',
            link: '/settings',
            icon: <GearIcon/>,
        }];

    return (
        <div className={`menu-container ${uxStore.isMenuCollapsed ? 'collapsed' : ''}`}>
            {menuItems.map((item) => (
                <div key={item.label}>
                    {item.key === MenuOptions.SETTINGS &&
                        <div className='menu-item-separator'/>}
                    <Link
                        to={`${customerStore.selectedCustomer?.name}${item.link}`}
                        className={`menu-item ${uxStore.selectedMenuItem === item.key ? 'selected' : ''}`}>
                        {item.icon}
                        <span>{item.label}</span>
                    </Link>
                </div>
            ))}
        </div>

    );
});
