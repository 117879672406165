import React, { useCallback, useEffect, useState } from 'react';
import './settings.scss';
import { observer } from 'mobx-react-lite';
import { ReactComponent as Filter } from '../../../../../../assets/svg/filter.svg';
import { Sidebar } from '../../../../../shared/sidebar/sidebar';
import { SettingsSidebar } from './settings-sidebar';
import { useStores } from '../../../../../store';
import ReactRouterPrompt from 'react-router-prompt';
import CustomModal from '../../../../../shared/custom-modal/custom-modal';

const Settings = observer(() => {
    const [showSidebar, setShowSidebar] = useState(false);
    const onSettingsIconClick = useCallback(() => setShowSidebar(true), []);
    const {useCaseStore, customerStore} = useStores();

    useEffect(() => {
        const handler = (event: BeforeUnloadEvent) => {
            if (useCaseStore.isDirty) {
                event.preventDefault();
                event.returnValue = '';
            }
        };
        window.addEventListener('beforeunload', handler);
        return () => {
            window.removeEventListener('beforeunload', handler);
        };
    }, []);

    const restoreData = (() => {
        useCaseStore.loadValues(customerStore.selectedCustomer.name);
    });

    return (<>
            <div className="settings-wrapper">
                <div className={'setting-icon'} onClick={onSettingsIconClick}><Filter/></div>
                <Sidebar sidebarComponent={<SettingsSidebar/>} isOpen={showSidebar} setIsOpen={setShowSidebar}/>
            </div>
            <div className='modal-container'>
            <ReactRouterPrompt when={useCaseStore.isDirty}>
                {({isActive, onConfirm, onCancel}) =>
                    <CustomModal
                        isOpen={isActive}
                        width={600}
                        title="Discard changes?"
                        onOk={() => {
                            restoreData();
                            onConfirm();
                        }}
                        onCancel={onCancel}
                        cancelButtonProps={{className: 'secondary-btn'}}
                        okText="Continue">
                        <br/>All changes will be lost, are you sure you want to continue?<br/><br/><br/>
                    </CustomModal>
                }
            </ReactRouterPrompt>
        </div>
        </>
    );
});

export default Settings;
