import './insights-filter.scss';
import { observer } from 'mobx-react-lite';
import { Select } from 'antd';
import { FREQUENCIES } from '../../../../utils/constants';
import React, { useEffect, useMemo, useState } from 'react';
import { useStores } from '../../../store';
import { InsightType } from '../../../../utils/enums';
import { SidebarButtons } from '../../../shared/sidebar/sidebar-buttons';
import DateRangePickerComponent from '../../../shared/date-range-picker/date-range-picker';
import { UIAlertsFilters, UIReportsFilters } from '../../../../dtos/insights-filter-conversion';

export interface InsightsFilterProps {
    onClose: () => void;
}

export const InsightsFilter = observer((props: InsightsFilterProps) => {
    const { insightsStore, useCaseStore } = useStores();
    const [temporaryFilter, setTemporaryFilter] = useState<UIReportsFilters | UIAlertsFilters>();
    const [useCases, setUseCases] = useState([]);

    useEffect(() => {
        if (useCaseStore.model?.usecases) {
            const useCasesList = useCaseStore.model.usecases.map((item) => {
                return {
                    value: item.name,
                    label: item.tabTitle
                };
            });
            useCasesList.unshift({ value: '', label: 'All' });
            setUseCases(useCasesList);
        }
    }, [useCaseStore.model]);

    useEffect(() => {
        setTemporaryFilter({...insightsStore.activeFilter});
    }, [props.onClose]);

    const handleInputChange = (field: string, value: string) => {
        setTemporaryFilter({
            ...temporaryFilter,
            [field]: value,
        });
    };

    const isReportsActive = useMemo(() => insightsStore.activeInsightType === InsightType.REPORT, [insightsStore.activeInsightType]);

    function onFilterApply() {
        insightsStore.setFilter(temporaryFilter);
        if (insightsStore.activeInsightType != InsightType.ACTIVITY) {
            insightsStore.loadInsights();
        }
        props.onClose();
    }

    function applyDisabled() {
        return !temporaryFilter.dateRange;
    }

    const handleDateRangeChange = (selectedOption, range) => {
        setTemporaryFilter({
            ...temporaryFilter,
            'dateRange': range ?? selectedOption,
        });
    };

    return temporaryFilter && (<div className='sidebar-filter-container'>
        <div className='filter'>
            <div className='filter-title'>Filters</div>
            <div className='filter-subtitle'>Date Range</div>
            <DateRangePickerComponent
                defaultValue={temporaryFilter.dateRange}
                onDateRangeChange={handleDateRangeChange}
                dayOffsetFilter={0}
            />

            {insightsStore.activeFilter.hasOwnProperty('useCase') && <>
                <div className='filter-subtitle'>Anomaly</div>
                <div className='use-case-select'>
                    <Select className={'filter-list'} size={'large'}
                            value={temporaryFilter.useCase} options={useCases} style={{width: '324px'}}
                            onChange={(value) => handleInputChange('useCase', value)}/>
                </div>
            </>}
            {isReportsActive && <>
                <div className='filter-subtitle'>Frequency</div>
                <div className='frequency-select'>
                    <Select className={'filter-list'} size={'large'}
                            value={(temporaryFilter as UIReportsFilters).reportFrequency} options={FREQUENCIES}
                            style={{width: '324px'}} onChange={(value) => handleInputChange('reportFrequency', value)}/>
                </div>
            </>
            }
        </div>
        <SidebarButtons applyButtonText='Apply' onApply={onFilterApply} applyDisabled={applyDisabled()}/>
    </div>);
});
