import axiosService from './axios.service';
import {UIBE_PREFIX} from '../utils/constants';

class UserService {
    public getUser = async () => {
        const res = await axiosService.get(`${UIBE_PREFIX}/auth/user-profile`);
        return res?.data;
    }
}

const userService = new UserService();
export default userService;
