import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useStores } from '../../../store';
import Navbar from './nav-bar/nav-bar';
import { SettingsSubMenu } from '../../../../utils/enums';
import './use-case-management.scss';
import { useParams } from 'react-router-dom';
import { Outlet, useNavigate } from 'react-router';

export const UseCaseManagement = observer(() => {

    const { useCaseStore } = useStores();
    const { useCaseName } = useParams<{ useCaseName: string }>();
    const navigate = useNavigate();

    useEffect(() => {
        useCaseStore.setActiveSubMenu(SettingsSubMenu.ANOMALIES);
    }, []);

    const isUseCaseMatch = useMemo(() => useCaseName === useCaseStore.currentUseCaseModel?.name,
    [useCaseName, useCaseStore.currentUseCaseModel]);

    useEffect(() => {
        if (useCaseStore.model && useCaseStore.data) {
            if (useCaseName) {
                useCaseStore.setSelectedUseCaseByName(useCaseName);
            } else {
                navigate(`${useCaseStore.currentUseCaseModel.name}`, { replace: true });
            }
        }
    }, [useCaseName, useCaseStore.model, useCaseStore.data]);

    return <div className='use-case-management'>
        { isUseCaseMatch && useCaseStore.data && <>
            <div className='sidebar-left'>
               <Navbar relativePath={'anomalies'} />
            </div>
            <div className='sidebar-right'>
                <Outlet/>
            </div>
        </>}
    </div>
});
