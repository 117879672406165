import React from 'react';
import moment from 'moment';
import { UIAlertsFilters, UIReportsFilters } from '../dtos/insights-filter-conversion';
import { UIAnomaliesFilter } from '../dtos/anomalies.dto';
import { DATE_OPTIONS_MAP } from './constants';
import DOMPurify from 'dompurify';

export const parseContent = (content) => {
    const regex = /(<br\/>|<b>[^<]*<\/b>)/g;
    const elements = content.split(regex);

    return elements.map((element, index) => {
        if (element.startsWith('<br/>')) {
            return React.createElement('br', { key: index });
        } else if (element.startsWith('<b>')) {
            const contentBold = element.slice(3, -4);
            const sanitizedContentBold = DOMPurify.sanitize(contentBold);
            return React.createElement('b', { key: index }, sanitizedContentBold);
        } else {
            return React.createElement('span', { key: index }, element);
        }
    });
};

export const convertHtmlContentResToValidDom = (res) => {
    // Extract the content inside the <body> tag, if it exists
    const bodyContentMatch = /<body[^>]*>([\s\S]*?)<\/body>/i.exec(res);
    const bodyContent = bodyContentMatch ? bodyContentMatch[1] : res;

    // Remove <html>, </html>, <o:p>, and </o:p> tags from the content (including any variations with spaces or new lines)
    return bodyContent.replace(/<\/?\s*(html|o:p)\s*>/g, '');
}

export const convertInitials = (name: string) => {
    return name.match(/(?<=^|,)\s*[A-Za-z]/g)?.map(s => s.trim()).reverse().join('').toUpperCase() || '';
}

export const decodeAndCleanHtmlContent = (content: string) => {
    const decodedContent = decodeURIComponent(content.replace(/\+/g," "));
    return convertHtmlContentResToValidDom(decodedContent);
}

export const parseValues = (inputValue: string) => {
    return inputValue.split(/[,;\n]/).map((v) => v.trim()).filter((v) => v !== '');
};

export const formatDate = (date: string, ignoreTime?: boolean) => {
    const format = `MM/DD/YYYY${ignoreTime ? '' : ' HH:mm'}`;
    return date ? moment.utc(date).local().format(format) : '';
}

const toUtcMoment = (date) => {
    return moment.utc().set({
        year: date.getFullYear(),
        month: date.getMonth(),
        date: date.getDate()
    });
}

export const toStartOfDayInUtc = (date) => {
    return toUtcMoment(date).startOf('day').valueOf().toString();
}

export const toEndOfDayInUtc = (date) => {
    return toUtcMoment(date).endOf('day').valueOf().toString();
}

export const handleDateRange = (dateRange) => {
    let fromDate, toDate;

    if (typeof dateRange === 'string') {
        const duration = DATE_OPTIONS_MAP[dateRange];

        if (duration.dayOffsetFilter) {
            fromDate = moment().utc().subtract(duration.value, duration.unit).startOf('day').valueOf().toString();
            toDate = moment().utc().subtract(duration.dayOffsetFilter, 'days').endOf('day').valueOf().toString();
        } else {
            fromDate = moment().utc().subtract(duration.value, duration.unit).valueOf().toString();
            toDate = moment().utc().valueOf().toString();
        }
    } else {
        fromDate = toStartOfDayInUtc(dateRange.from);
        toDate = toEndOfDayInUtc(dateRange.to);
    }

    return { fromDate, toDate };
}

export const createBaseWidgetUrl = (baseUrl: string, oem: string, apn: string, dateRange: any, worldRegion: string) => {
    const { fromDate, toDate } = handleDateRange(dateRange);

    return baseUrl.replaceAll('{oem}', oem)
        .replace('{apn}', apn)
        .replace('{from}', fromDate)
        .replace('{to}', toDate)
        .replace('{world_region}', worldRegion);
}

export const dateRangeDescription = (dateRange: any) => {
    if (typeof dateRange === 'string') {
        return dateRange;
    } else {
        return `${formatDate(dateRange.from, true)} - ${formatDate(dateRange.to, true)}`;
    }
}

const convertDateRange = (dateRange) => {
    if (dateRange && typeof dateRange !== 'string') {
        dateRange.from = new Date(dateRange.from);
        dateRange.to = new Date(dateRange.to);
    }
    return dateRange;
}

export const convertSavedFilterToObject = (storedFilter: string) => {
    let filter = JSON.parse(storedFilter);
    filter.dateRange = convertDateRange(filter.dateRange);
    return filter;
}

export const convertSavedFilterToMap = (storedFilter: string) => {
    let parsedFilter = JSON.parse(storedFilter);
    let filterMap = new Map<any, UIAnomaliesFilter | UIReportsFilters | UIAlertsFilters>(parsedFilter);

    filterMap.forEach((filter) => {
        filter.dateRange = convertDateRange(filter.dateRange);
    });

    return filterMap;
}
