import './insights.scss';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import {ReactComponent as Filter} from '../../../assets/svg/filter_list_black_24dp 2.svg';
import {ReactComponent as Export} from '../../../assets/svg/export_16.svg';
import ButtonComponent from '../../shared/button/button-component';
import { useStores } from '../../store';
import { InsightsSubMenu, InsightType, MenuOptions } from '../../../utils/enums';
import { Outlet } from 'react-router';
import { printListOfDocuments } from '../../../utils/file-saver';
import { SubMenu } from '../../shared/sub-menu/sub-menu';
import { Sidebar } from '../../shared/sidebar/sidebar';
import { InsightsFilter } from './insights-filter/insights-filter';
import insightsService from '../../../services/insights.service';
import { dateRangeDescription } from '../../../utils/parse';
import { UIReportsFilters } from '../../../dtos/insights-filter-conversion';

export const Insights = observer(() => {
    const [isDownloading, setIsDownloading] = useState(false);
    const [showSidebar, setShowSidebar] = useState(false);
    const {uxStore, insightsStore, customerStore, useCaseStore} = useStores();

    useEffect(() => {
        uxStore.setSelectedMenuItem(MenuOptions.INSIGHTS);
    }, []);

    const exportSelectedRow = async () => {
        setIsDownloading(true);
        const listForExport = await insightsService.getInsightsHtmlList(insightsStore.activeInsightType, customerStore.selectedCustomer.name, insightsStore.activeSelectedItems);
        await printListOfDocuments(insightsStore.activeSelectedItems, listForExport, insightsStore.activeInsightType);
        setIsDownloading(false);
    };

    function exportDisabled() {
        return insightsStore.activeSelectedItems.length === 0;
    }

    return (<div className='insights'>
        <div className='title-container'>
            <div className='title'>Insights</div>
            {useCaseStore.useCaseDisplayValues.size > 0 &&
                <div className='insights-filter-container'>
                    <div className='breadcrumb'>{dateRangeDescription(insightsStore.activeFilter.dateRange)}</div>
                    {insightsStore.activeFilter.hasOwnProperty('useCase') &&
                        <div className='breadcrumb'>{useCaseStore.useCaseDisplayValues.get(insightsStore.activeFilter.useCase)?.displayName
                        || 'All Anomalies'}</div>
                    }
                    {insightsStore.activeInsightType === InsightType.REPORT && <div
                        className='breadcrumb'>{(insightsStore.activeFilter as UIReportsFilters).reportFrequency.toLowerCase()
                        || 'All Frequencies'}</div>}
                    <div className='filter-icon'>
                        <ButtonComponent text='' onClick={() => setShowSidebar(true)} icon={Filter}/>
                    </div>
                    <ButtonComponent text={isDownloading ? 'Exporting' : 'Export'} onClick={exportSelectedRow}
                                     icon={Export} isLoading={isDownloading} disabled={exportDisabled()}/>
                </div>
            }
        </div>
        <SubMenu
            options={Object.values(InsightsSubMenu) as string[]}
            activeSubMenu={insightsStore.activeSubMenu}
        />
        <div className='component-container'>
            <Outlet/>
        </div>
        <Sidebar sidebarComponent={<InsightsFilter onClose={() => setShowSidebar(false)}/>}
                 isOpen={showSidebar} setIsOpen={setShowSidebar}/>
    </div>)
});
