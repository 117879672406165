import React from 'react';
import { observer } from 'mobx-react-lite';
import { Switch } from 'antd';
import './settings-sidebar.scss';
import { useStores } from '../../../../../store';

export const SettingsSidebar = observer(() => {
    const {useCaseStore} = useStores();

    const toggle = (setting: any) => {
        return (
            <label key={setting.fieldName} className={'toggle'}>
                <span>{setting.fieldDisplayName}</span>
                <Switch
                    onChange={(checked) => handleChange(setting.fieldName, checked)}
                    checked={useCaseStore.currentUseCaseValues[setting.fieldName]}
                />
            </label>
        );
    };

    const group = (setting: any) => {
        const optionsValue = useCaseStore.currentUseCaseValues[setting.fieldName] || [];
        return (
            <div className={'group'} key={setting.fieldName}>
                <div className={'group-title'}>{setting.fieldDisplayName}</div>
                {setting.options.map(option => {
                    return <label key={option} className={'toggle'}>
                        <span>{option}</span>
                        <Switch
                            onChange={(checked) => handleGroupChange(setting.fieldName, option, checked, optionsValue)}
                            checked={(optionsValue).includes(option)}
                        />
                    </label>
                })}
            </div>
        );
    };

    const handleChange = (fieldName: string, checked: boolean) => {
        useCaseStore.updateSettings(fieldName, checked);
    };

    const handleGroupChange = (fieldName: string, option: string, checked: boolean, options: string[]) => {
        let optionsCopy = [...options];
        if (checked) {
            optionsCopy.push(option);
        } else {
            optionsCopy = optionsCopy.filter(item => item !== option);
        }
        useCaseStore.updateSettings(fieldName, optionsCopy);
    };

    return (<>
        <div className={'setting-title'}>Preferences</div>
        {useCaseStore.currentUseCaseModel.settings.map((setting) => {
            if (setting.type === 'boolean') {
                return toggle(setting);
            } else if (setting.type === 'group') {
                return group(setting);
            }
            return null;
        })}</>)
});
