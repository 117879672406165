import './sidebar-buttons.scss';
import { observer } from 'mobx-react-lite';
import { Button } from 'antd';
import React from 'react';

export interface SidebarButtonsProps {
    applyButtonText: string;
    onApply: () => void;
    applyDisabled?: boolean;
}

export const SidebarButtons = observer((props: SidebarButtonsProps) => {
    const {applyButtonText, onApply, applyDisabled} = props;

    return (<div className='buttons-container'>
        <Button type="primary" onClick={onApply} disabled={applyDisabled} data-testid='apply-btn'>{applyButtonText}</Button>
    </div>);
});
