import { BaseStore } from './base.store';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { RootStore } from './index';
import useCaseService from '../../services/use-case.service';
import { SettingsSubMenu } from '../../utils/enums';

export class UseCaseStore extends BaseStore  {
    public data: any;
    public model: any;
    public selectedUseCase = 0;
    public apnAllList = [];
    public apnNames = [];
    public isDirty = false;
    public activeSubMenu: SettingsSubMenu = SettingsSubMenu.ANOMALIES;
    public useCaseDisplayValues: Map<string, {displayName: string, hideOnExceptionList: boolean}> = new Map([]);
    public isError = false;
    public auditLogs: Map<string, AuditLogEntry> = new Map();

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this, {
            data: observable,
            model: observable,
            selectedUseCase: observable,
            setSelectedUseCaseByName: action,
            setSelectedUseCase: action,
            loadValues: action,
            loadTemplate: action,
            currentUseCaseModel: computed,
            currentUseCaseValues: computed,
            updateSettings: action,
            apnNames: observable,
            removeApn: action,
            changeParamValueByApn: action,
            changeOemParamValue: action,
            apnPreferences: computed,
            unSelectedApn: computed,
            addApnList: action,
            valueByFieldName: observable,
            savePreferences: action,
            isDirty: observable,
            activeSubMenu: observable,
            setActiveSubMenu: action,
            useCaseDisplayValues: observable,
            initDestValues: action,
            destValueByType: observable,
            isAuthorizedUseCase: computed,
            oemParameters: computed,
            currentUseCaseName: computed,
            dashboardRecordsLimit: computed,
            insightsRecordsLimit: computed,
            isError: observable,
            useCaseDayOffsetFilter: computed,
            inactiveUseCaseWithApn: computed,
            isActiveByUseCaseName: observable,
            setSelectedUseCaseToFirstActive: action,
            isRoamingUseCase: computed,
            auditLogs: observable,
            addAuditLog: action,
        });
    }

    public async loadTemplate() {
        try {
            this.isError = false;
            this.setIsLoading(true);
            const useCaseModel = await useCaseService.getUseCaseModel();
            runInAction(() => {
                this.model = useCaseModel;
                this.setUseCaseDisplayValues();
                this.setIsLoading(false);
            })
        } catch (e) {
            runInAction(() => {
                this.setIsLoading(false);
                this.isError = true;
            })
        }
    }

    public async loadValues(customer: string) {
        try {
            this.setIsLoading(true);
            this.isError = false;
            const useCaseValues = await useCaseService.getUseCaseValues(customer);
            const apnAllList = await useCaseService.getApnList(customer);
            runInAction(() => {
                this.data = useCaseValues;
                this.apnAllList = apnAllList;
                this.filterApnNamesByAllowedCategories();
                this.setIsLoading(false);
                this.isDirty = false;
                this.auditLogs = new Map();
            })
        } catch (e) {
            runInAction(() => {
                this.isError = true;
                this.setIsLoading(false);
            })
        }
    }

    public async savePreferences() {
        try {
            this.setIsLoading(true);
            await useCaseService.savePreferences(this.data, Array.from(this.auditLogs.values()));
            runInAction(() => {
                this.setIsLoading(false);
                this.isDirty = false;
                this.auditLogs = new Map();
            })
        } catch (e) {
            runInAction(() => {
                this.setIsLoading(false);
                this.auditLogs = new Map();
            })
            throw e;
        }
    }

    get currentUseCaseModel(){
        return this.model?.usecases?.[this.selectedUseCase];
    }

    get isAuthorizedUseCase() {
        return this.currentUseCaseModel.name === 'unauthorized_destination';
    }

    get currentUseCaseValues() {
        const currentName = this.currentUseCaseModel?.name;
        return this.data?.useCasePreferences?.find(useCase => useCase.name === currentName);
    }

    get apnPreferences() {
        return this.currentUseCaseValues.preferences.apnPreferences;
    }

    get oemParameters() {
        return this.currentUseCaseValues.preferences.oemParameters;
    }

    get unSelectedApn() {
        const nameList = this.apnPreferences.map(apn => apn.apnName );
        return this.apnNames.filter(name => !nameList.includes(name));
    }

    get useCaseDayOffsetFilter() {
        return this.currentUseCaseModel?.dayOffsetFilter || 0;
    }

    private isEmptyApn(useCaseName, apnPreferences) {
        const useCaseModel = this.model?.usecases?.find(useCase => useCase.name === useCaseName);
        return useCaseModel?.table && (!apnPreferences || !apnPreferences.length);
    }

    get inactiveUseCaseWithApn() {
        return this.data?.useCasePreferences
            .filter(useCase => useCase.isUseCaseActive &&
                this.isEmptyApn(useCase.name, useCase.preferences.apnPreferences))
            .map(u => this.model?.usecases?.find(useCase => useCase.name === u.name).tabTitle);
    }

    public valueByFieldName(colName: string, apnName: string) {
        const apnIndex = this.apnPreferences.findIndex(app => app.apnName === apnName);
        const index = this.apnPreferences[apnIndex]?.apnParameters?.findIndex(param => param.parameterName === colName);
        return this.apnPreferences[apnIndex].apnParameters[index]?.parameterValue;
    }

    public destValueByType(type: string, apnName: string) {
        return this.valueByFieldName(`${type}_list_destinations`, apnName);
    }

    public addApnList(apnList) {
        this.isDirty = true;
        apnList.forEach((apnName => this.addApn(apnName)));
    }

    public setSelectedUseCaseByName(name : string) {
        const index = this.model?.usecases?.findIndex(useCase => useCase.name === name);
        this.setSelectedUseCase(index);
    }

    public setSelectedUseCase(index) {
        runInAction(() => {
            this.selectedUseCase = index;
            this.filterApnNamesByAllowedCategories();
        })
    }

    private filterApnNamesByAllowedCategories() {
        this.apnNames = this.apnAllList.filter(apn => {
            return (
                apn.type !== 'shared' &&
                apn.oem === this.rootStore.customerStore.selectedCustomer.name &&
                (!apn.category || !this.currentUseCaseModel.allowedApnCategories || this.currentUseCaseModel.allowedApnCategories.includes(apn.category))
            );
        }).map(apn => apn.name);
    }

    public updateSettings(fieldName: string, checked: any) {
        this.isDirty = true;
        const origValue = this.currentUseCaseValues[fieldName];
        this.currentUseCaseValues[fieldName] = checked;
        this.addAuditLog(fieldName, 'update', origValue, checked, null);
    }

    private addApn(apnName: string) {

        const extractParameters = (col): { parameterName: string; parameterValue: number }[] => {
            if (col.type === 'pair' && col.items) {
                return col.items.flatMap(extractParameters);
            }
            return [{ parameterName: col.colField, parameterValue: col.defaultValue  }];
        };

        const colFields = this.currentUseCaseModel?.table?.tableColumn
            ?.filter(col => col.colField !== 'apnName')
            ?.flatMap(extractParameters) ?? [];

        this.addAuditLog('apn', 'add', null, colFields, apnName);

        this.apnPreferences.push({ apnName: apnName, apnParameters: colFields});
    }

    public removeApn(apnName: string) {
       this.isDirty = true;
       this.currentUseCaseValues.preferences.apnPreferences = this.apnPreferences.filter((item) => item.apnName !== apnName);
       this.addAuditLog( 'apn', 'remove', apnName,  null, apnName);
    }

    public changeOemParamValue(colName, value) {
        this.isDirty = true;
        const index = this.oemParameters?.findIndex(param => param.parameterName === colName);
        const origValue = this.oemParameters[index].parameterValue;
        this.oemParameters[index].parameterValue = value;
        this.addAuditLog(colName, 'update', origValue, value, null);
    }

    public changeParamValueByApn(apnName, colName, value) {
        this.isDirty = true;
        const apnIndex = this.apnPreferences.findIndex(app => app.apnName === apnName);
        const index = this.apnPreferences[apnIndex]?.apnParameters?.findIndex(param => param.parameterName === colName);
        const origValue = this.apnPreferences[apnIndex].apnParameters[index].parameterValue;
        this.apnPreferences[apnIndex].apnParameters[index].parameterValue = value;
        this.addAuditLog(colName, 'update', origValue, value, apnName);
    }

    public initDestValues(apnName, listTypeValue: { listType: 'black' | 'white', parameterValues: any[] }[]) {
        this.isDirty = true;
        const apnIndex = this.apnPreferences.findIndex((app) => app.apnName === apnName);

        ['black', 'white'].forEach((listType) => {
            const parameterName = `${listType}_list_destinations`;
            const listItem = listTypeValue.find((item) => item.listType === listType);
            const parameterValue = listItem ? listItem.parameterValues : [];

            this.apnPreferences[apnIndex]?.apnParameters?.push({
                parameterName,
                parameterValue,
            });

            this.addAuditLog(parameterName, 'add', null, parameterValue, apnName);
        });
    }

    public setActiveSubMenu(activeSubMenu: SettingsSubMenu) {
        this.activeSubMenu = activeSubMenu;
    }

    private setUseCaseDisplayValues() {
        this.useCaseDisplayValues.clear();
        this.useCaseDisplayValues.set('all', {displayName: 'All Anomalies', hideOnExceptionList: false});
        this.model.usecases.forEach((useCase) => {
            this.useCaseDisplayValues.set(useCase.name, {displayName: useCase.tabTitle, hideOnExceptionList: useCase.hideOnExceptionList});
        });
    }

    get currentUseCaseName() {
        return this.currentUseCaseModel?.name;
    }

    get dashboardRecordsLimit() {
        return this.model?.dashboard.recordsLimit;
    }

    get insightsRecordsLimit() {
        return this.model?.insights.recordsLimit;
    }

    get isRoamingUseCase() {
        return ['volumetric_roaming', 'volumetric_ue_roaming'].includes(this.currentUseCaseName);
    }

    public isActiveByUseCaseName(useCaseName: string) {
        return this.data?.useCasePreferences?.find(useCase => useCase.name === useCaseName)?.isUseCaseActive;
    }

    public setSelectedUseCaseToFirstActive() {
        const useCaseFirstActive = this.model?.usecases?.filter(useCase => !useCase.hideOnNavbar).find(useCase => {
            const useCasePreferences = this.data?.useCasePreferences?.find(useCasePreference => useCasePreference.name === useCase.name);
            return useCasePreferences?.isUseCaseActive;
        });

        if (useCaseFirstActive) {
            this.setSelectedUseCaseByName(useCaseFirstActive.name);
        }
    }

    public addAuditLog(parameterName: string, actionType: ActionType, origValue: any, newValue: any, entityName: string | null) {
        const key = `${this.currentUseCaseName}-${parameterName}-${actionType}-${entityName}`;

        const entry: AuditLogEntry = {
            flowNameL1: 'Settings',
            flowNameL2: 'Anomalies',
            flowNameL3: this.currentUseCaseName,
            parameterName,
            actionType,
            origValue,
            newValue,
            entityName
        };

        const existingEntry = this.auditLogs.get(key);
        if (existingEntry) {
            existingEntry.newValue = entry.newValue;
        } else {
            this.auditLogs.set(key, entry);
        }
    }
}
